import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import DeleteConfirmModal from '@fuse/components/Modal/DeleteConfirmModal';
import { customCaseInsensitiveStringSort } from '@fuse/utils/aggridCustomSort';
import ActivityAgGridListingSameAsSam from '@fuse/components/ActivitieLogs/activitiesAgGridListingSameAsSam';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "TICKET_PRIORITY";
	const router = useRouter();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const gridRef = useRef();
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [editData, setEditData ]  = useState({});
	const [editButtonClick, setEditButtonClick ]  = useState(false);
	const [editDone, setEditDone ]  = useState(false);
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [priorityData, setPriorityData] = useState(null)
	const [filterApplied, setFilterApplied] = useState(false);
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			// dispatch(setfilterlist({}));
			loadInitialState(params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
			getReport(params);
		}
	}

	const tab_value = useSelector(
		(state) => state.reportApp.reportViewDialogReducer.tab_value
	);

	useEffect(()=>{
		setTabValue(tab_value)
	}, [tab_value])

	function onSortChanged(params){
		if (router.query.report_name == undefined) {
		  const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
		  saveView(rows);
		}
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		saveView("N/A", rows,gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "department";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
		axios
		  .get(`/user-view/show-view-order?table_name=${table_name}`)
		  .then((results) => {
			const { data } = results.data;
			if (data !== null) {
			  const order = JSON.parse(data.order);
			  const filter = JSON.parse(data.filter);
			  gridRef.current = {...gridRef.current,filterModelArr:filter}																							
			  params?.columnApi.applyColumnState({ query_data: order });
			  params?.api.setFilterModel(filter);
				if (Object.keys(filter)?.length) {
					setFilterApplied(true)
				}
			}
		  })
		  .catch((err) => {
			console.log(err);
		  });
	}

	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
		payload = {
			filter:  JSON.stringify(filter),
			table_name : table_name
		}
		}else{
		payload = {
			order:  JSON.stringify(rows),
			table_name : table_name
		}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
		console.log('view updated')
		})
		.catch(err=>{
		console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}

		if (router.query.report_name == undefined) {
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];
			if (first_row.colId !== "ag-Grid-AutoColumn") {
			  dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			}
		  }	
	}

	const handleConfirm = (data) => {
		setPriorityData(data)
	}

	const handleClose = () => {
		setPriorityData(null)
	}

	function handelDelete(){
		axios.delete(`/service-request-priority/delete/${priorityData?.id}`).then((res)=>{
			handleClose()
			toast.success('Service ticket priority deleted successfully.')
			props.refresh()
		}).catch((err)=>{
			toast.error(err.response.data.message);
			setPriorityData(null)
			
			// toast.error(err.data.message);
		})
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	// function onCellValueChanged(e){
	// 	const payload = {
	// 		department_name:e.data.department,
	// 		edit_id:e.data.id,
	// 	}
	// 	if(!payload.department_name){
	// 		toast.error('Department Name is required')
	// 		props.refresh()
	// 		return
	// 	}
	// 	axios.post('/department/edit', payload).then(response => {
	// 		if(response.status == 201){
	// 			toast.success(response.data.message)
	// 			props.refresh()
	// 		} else {
	// 		//   onSuccess()
	// 		  toast.success('Department updated Successfully.')
	// 		  props.refresh()
	// 		//   props.fetchAllHardwareAssets()
	// 		}
			
		  
	// 	}).catch(err => {
	// 	  console.log('212=>',err);
	// 	  toast.error('Something went wrong!')
	// 	})

	// }

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])
	
	useEffect(()=>{
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.endUsersAccounts])


	function handelEdit(data){
		dispatch(setFromData({id:data.id,
			priority_name:data.name,
			priority_color: data.priority_color,
		}))
		dispatch(openFormViewerDialog())		
	}

	// function editCloseForm(data){
	// 	if(data){
	// 		setEditDone(true)
	// 		setEditButtonClick(false)
	// 	}
	// }
	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	function formatLogs(data){
		let length = data.length
		return data.map((item)=>{
			const deptObject = {
				id:item.id,
				action_id: `act${length}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				department_name : item?.department?.department_name || item.department_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
			}
			length--
			return deptObject
		})
	}

	function getLogs(){
		setLoadingLog(true)
		axios.get('/service-request-priority/activities')
		.then(({data})=>{
			setLoadingLog(false)
			setLogs(formatLogs(data.data.activities));
		})
		.catch(err=>{
			console.log('cannot fetch logs');
		})
	}

	useEffect(()=>{
		if(tabValue == 1){
			getLogs()
			setFilterApplied(false)
		}
	},[tabValue])

	function handleOpenForm(){
		dispatch(openFormViewerDialog())
	}

	return (
		<>	{ (tabValue == 0) &&
				<AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'service_ticket_priority'} logs={logs} displayTableName = {'Service Ticket Priority'}
				 onClick={handleChildClick}
				 onClickAddButton={handleOpenForm}
				 addPermission = {props.addDepartmentPermission}
				 loadingLog={loadingLog}
			/>}		
			{(tabValue == 1) && (<ActivityAgGridListingSameAsSam module={'service_ticket_priority'} logs={logs} displayTableName = {'Service Ticket Priority'}
				addPermission = {props.createSLAggrementPermission}
				loadingActivityLog={loadingLog}
				/>)}
			{(tabValue == 0) && <div className="ag-theme-alpine ag-single-col" style={{width: "100%",height:windowHeight+105,fontSize:'11px'}}>
				<AgGridReact
					ref={gridRef}
					rowData={props.endUsersAccounts}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowHeight = {30}
					// onGridSizeChanged = { onGridSizeChanged }
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					gridOptions = {{
						suppressHorizontalScroll: false,
						scrollbarWidth: 0
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
					
					// get filter model
					onFilterChanged = { onFilterChanged }		
					onColumnMoved = { onColumnMoved }	
					onColumnVisible = { onColumnVisible }
					onColumnPinned = { onColumnPinned }
					onSortChanged={onSortChanged}

					//row grouping
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }
					onColumnRowGroupChanged = { onColumnRowGroupChanged }

					groupHeaderHeight ={1}
					floatingFiltersHeight = {40}
					frameworkComponents={frameworkComponents}

					pagination={true}
					defaultColDef={{
						// flex: 1,
						// minWidth: 180,
						// sortable: true,
						enableRowGroup:true,
						resizable: true
					}}
				>
					<AgGridColumn 
						lockPosition={true}
						rowSelection={'single'}
						width={120}
						minWidth={120}
						maxWidth={120}
						suppressSizeToFit={true}
						headerName="Actions"
						pinned= 'right' 
						className="action_field"
						lockPinned={true}
						cellRendererFramework={({data}) =>{
							if(data){
								return (
									<>
										{!data.system_generated && props.updateDepartmentPermission && <Tooltip id="edit" title="Edit" placement="top">
										<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-pencil" onClick={() => handelEdit(data)} />
										</Tooltip>}
										{!data.system_generated && props.deleteDepartmentPermission && <Tooltip id="delete" title="Delete" placement="top" >
										<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-trash" onClick={() => handleConfirm(data)} />
										</Tooltip>}
									</>
								)
							}else{
								return (<></>)
							}
						}}
					>
					</AgGridColumn>
					<AgGridColumn 
						field="priority_name" 
						headerName= "Priority Name" 
						editable={false}
						comparator={customCaseInsensitiveStringSort}
						cellRendererFramework={(event) =>{
							if(event.colDef.field === "priority_name"){
								return (
									<span>
										<span style={{
											height:'10px',
											width:'10px',
											marginTop: '2px',
											borderRadius: '50%',
											display: 'inline-block',
											backgroundColor: event.data?.priority_color?.rgba || event.data?.priority_color?.code
										}}></span>
										<span style={{marginLeft: '5px'}}>{event.data?.priority_name}</span>
									</span>
								)
							}else if(event.colDef.field === "status_description"){
								return <>{event.data?.status_description}</>
							}else if(event.colDef.headerName == 'Group' && event.node.field == 'status_name_'){
								return <><span>{event.value}</span></>
							}else{
								return (<><span>{event.value}</span></>)
							}
						}}
						// onCellValueChanged = {onCellValueChanged}
						sortable={ true } filter="text"
						headerComponentFramework={(props)=>(CustomFilter(props, "priority_name", gridRef))} 
						flex = {1}
						minWidth={200}
					>
					</AgGridColumn>					
				</AgGridReact>
				<DeleteConfirmModal 
						openAlerModal={priorityData}
						module={'Service Ticket Priority'}
						handleCloseAlertModal={handleClose} 
						handleAlertModal={handelDelete}
					/>
				{/* <GeneralModal
					open={departmentData}
					title={'Delete Department'}
					text={<h4>Are you sure to delete this Priority named as <b>{departmentData?.priority_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handelDelete}
				/> */}
			</div>}
		</>
	);
}

export default ListView

import _ from '@lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import { getLocalTimeFromUTC } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
    const classes = useStyles();
	const table_name = "ASSETSTATUS";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');	
	const [tabState, setTabState] = useState("seats");
	const [incidents, setIncidents] = useState();
	const [aggridParam, setAggridParam] = useState([])
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 290);
	const [groupByArr, setGroupByArr] = useState([])
  	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const skipHeader = [
		"id", "host_id", "created_at", "updated_at"
	]
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const [filterApplied, setFilterApplied] = useState(false);
	//const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const tableHeaders = [
		"problem_id", "serial_no", "host_name", "event_severity", "event Date & Time", "recovery_time",
		"event_status", "alert_subject", "event_duration", "company_name"
	  ];
	async function getIncidents(params){
		try {
			const {startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode} = params.request;
			axios.post(`/zabbix/get-all-incident`,{
				dataSourceParams: {
				  startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys, pivotMode
				}
			  }).then((res)=>{
				/////if(res.data.data.rows.length === 0) gridRef.current.api.showNoRowsOverlay();
				params.successCallback(formatData(res.data.data.rows), res.data.data.lastRow);
			  }).catch((err) => {
				console.log('error', err)
				params.failCallback();
			  })
			//const res = await axios.get("/zabbix/get-all-incident");
			//const { incidents } = res.data.data;
			//setIncidents(formatData(incidents));
		} catch (err) {
			console.log(err);
		}finally {
		}
	}

	function formatData(data){
		return data.map((item) => {
			return {
				problem_id: item.problem_id,
				serial_no: item.serial_no,
				host_name: item.host_name,
				event_severity: item.event_severity,
				'event Date & Time': getLocalTimeFromUTC(item.created_at),
				recovery_time: item.recovery_time,
				event_status: item.event_status,
				alert_subject: item.alert_subject,
				event_duration: item.event_duration,
				asset_id: item.asset_id || null,
				company_name: item.company_name || ''
			}
		})
	}

	const datasource = {
		getRows(params) {
		  // Call the function to fetch asset data from the server.
		  getIncidents(params)
		}
	  };
	function formatString(str){
        if(!str) return ''
        return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
    }

	const onGridReady = (params) =>{
		setAggridParam(params)
		params.api.sizeColumnsToFit()
		// setGridApi(params)
		if (router.query.report_name == undefined) {
		  params?.api.setServerSideDatasource(datasource);
		  loadInitialState(params)
		  dispatch(setfilterlist({}));
		  // dispatch(setrowGroupModel([]));
		  // loadInitialState(params);
		  dispatch(setrowGroupModel([]));
		}
		else {
		  setRowGroupPanelShow('always')
		  getReport(params);
		  // loadInitialState(params)
		}
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		// dispatch(setfilterlist(rows));
		// validation for no-overlay 
		dispatch(setfilterlist(rows));
		saveView("N/A", rows,gridRef.current.filterModelArr)
		const total_rows = event.api.getDisplayedRowCount();

		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "asset status";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
        	event.columnApi.setColumnState(row_model);
        	event?.api.setServerSideDatasource(datasource);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
        if(!table_name) return;
        axios.get(`/user-view/show-view-order?table_name=${table_name}`)
        .then(results=>{
            const { data } = results.data;
			if (data !== null) {
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data?.filter);
				if (Object.keys(filter || {})?.length) {
					setFilterApplied(true)
				  }
				  gridRef.current = {...gridRef.current,filterModelArr:filter}

				  params?.columnApi.setColumnState(order);
				  params.api.setFilterModel(filter);
				}
				// gridRef.current.api.setFilterModel(filterModel);
				params?.api.setServerSideDatasource(datasource);
        })
        .catch(err=>{
            console.log(err);
        }).finally(() => {
			params?.api.setServerSideDatasource(datasource);
		  })
    }

    const saveView = (rows, filter) => {
        if(!table_name) return;
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
    }

    const onColumnMoved = (params) => {
        const rows = params.columnApi.getColumnState();
        saveView(rows);
		dispatch(setrowGroupModel(rows))
    }

    function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
			saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event){
		const report_name = router.query.report_name;
		if(report_name == undefined){
			const rows = event.columnApi.getColumnState();
			const first_row = rows[1];	
			if(first_row.colId !== 'ag-Grid-AutoColumn'){
				saveView(rows);
				dispatch(setrowGroupModel([]));
			}else{
				dispatch(setrowGroupModel(rows));
			}
		}
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
		}
	}

	function redirectToAssetProfile(params) {
		if(!params.data.asset_id) return
    	router.push(`/assets-list/${params.data.asset_id}`);

	}
	const generateHeaderLabel = (header) => {
		if(header == 'problem_id'){
			return 'Problem Number'
		} else if(header == 'serial_no'){
			return 'Serial Number'
		} else if(header == 'event_subject'){
			return 'Problem'
		} else if(header == 'event_status'){
			return 'Status'
		} else if(header == 'event_severity'){
			return 'Severity'
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	//useEffect(()=>{
//		gridRef.current.api.setQuickFilter(
		//	query_data
		//);
	//},[query_data])

	function onSortChanged(params) {
		const rows = params.columnApi.getColumnState();
		if (router.query.report_name == undefined) {
		  dispatch(setrowGroupModel(rows))
		  saveView(rows);
		}
	
	  }

	return (	
		<>
		<AgGridFilterGroupView
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= {'Insight log'}
				displayTableName={'Insights Log'}
			/>
			<div
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'11px'
				}}
			>
				<AgGridReact
					ref = { gridRef}
					onGridReady = { onGridReady }
					animateRows = { true }
					//rowData = {props.endUsersAccounts}
					rowHeight = {30}
					rowModelType='serverSide'
					serverSideStoreType="partial"
					defaultColDef={{
						enableRowGroup:true,
						sortable: true,
						resizable: true
					}}
					defaultExcelExportParams={{
					sheetName: 'Dalos-Source'
					}}
					pagination
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }

					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}
					onSortChanged={onSortChanged}
					// get filter model
					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }
				>
					{tableHeaders.map(header => {
						if(!skipHeader.includes(header)){
							return(
								<AgGridColumn 
									key = {header} 
									field = {header}
									headerName = {generateHeaderLabel(header)}  
									sortable = {true} 
									filter = "text"
									flex = {1}
									minWidth={200}
									headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}									
									cellRendererFramework={(event) =>{
										if(event.colDef.field === "serial_no"){
											return (<a 
												style={{
													height:'10px',
													width:'10px',
													color: 'black',
													cursor: 'pointer',
													marginTop: '2px',
													borderRadius: '50%',
													display: 'inline-block',
												}} 
												onClick={()=> redirectToAssetProfile(event)} 
												variant="subtitle1"
											>
												{<><span>{event.value}</span></>}
											</a>)
										}else{
											return(<span>{event.value}</span>)
										}
									}}
								></AgGridColumn>
							)
						}							
					})}
				</AgGridReact>
			</div>
		</>
	);
}

export default ListView

import EndUsersAccountsTable from './tables';
import useToast from '@fuse/hooks/useToast'
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from "@fuse/utils/axios";
import useRouter from '@fuse/hooks/useRouter';
import { dateReformatTo_mmm_dd_yyyy } from '@fuse/utils/dateOperations';
import reducer from '../store';
import withReducer from 'app/store/withReducer';
import AddAgentForm from './addAgentForm';


const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentWrapper : {
		marginBottom:'15px',
		borderRadius:'10px'
	}
});

function CardedFullWidth2TabbedSample(props) {
	const router = useRouter();
	const classes = useStyles();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [loading, setLoading] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [agentsList, setAgentsList] = useState([]);
	const openState = useSelector((state) => state.ServiceRequestsApp.formViewDialog)
	const [ redirectedWith, setRedirectedWith ] = useState(null);


	function flattenResponse( agents ){
		return agents.map((item)=>{
			return {
				id: item.id,
				first_name: item.first_name,
				last_name: item.last_name,
				email_address: item.email,
				phone_number: item.phone,
				status: item.agent_status,
				level:item.agent_level,
				role: item.agent_type === 'agent' ? 'Agent' : 'Admin',
				created_date: dateReformatTo_mmm_dd_yyyy(item.created_at),
				updated_date: dateReformatTo_mmm_dd_yyyy(item.updated_at)
			}
		})
	}

	function getAllAgents(){
		axios.get('/agent-management').then((res)=>{
			setAgentsList(flattenResponse(res.data.data.agents));
		}).catch((err)=>{
			console.log(err)
		})
	}

	useEffect(()=>{
		getAllAgents();
	},[]);

	function refreshList(){
		getAllAgents()
	}

	return (
		<div>
			<div className='px-32'>
				<div className={selectedTab !== 0 ? 'hidden' : ''}>
					<EndUsersAccountsTable
						agentsList={agentsList}
						loading={loading}
						tableName='agents'
						customFilterData={redirectedWith}
						refresh={refreshList}
					/>
					{openState.open && <AddAgentForm getAllAgents = {getAllAgents}/>}
				</div>
			</div>
		</div>
	)
}

export default withReducer('AgentApp', reducer)(CardedFullWidth2TabbedSample);

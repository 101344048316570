import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import DeleteConfirmModal from '@fuse/components/Modal/DeleteConfirmModal';
import { customCaseInsensitiveStringSort } from '@fuse/utils/aggridCustomSort';
import ActivityAgGridListingSameAsSam from '@fuse/components/ActivitieLogs/activitiesAgGridListingSameAsSam';

function ListView(props) {
	const table_name = "SERVICE_REQUEST_SOURCE";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	// const rowGroupPanelShow = 'always';
	const toast = useToast(dispatch);
	const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
	const [serviceRequestSource, setServiceRequestSource] = useState(null)
	const [logs, setLogs] = useState([]);
	const [loadingLog, setLoadingLog] = useState(false);
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [filterApplied, setFilterApplied] = useState(false);
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const skipHeader = [
		"id", "platform_customer_id",
		"created_at", "updated_at_", 'comany_name','is_system_generated'
	]
	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);

	const handleConfirm = (data) => {
		setServiceRequestSource(data)
	}

	const handleClose = () => {
		setServiceRequestSource(null)
	}

	const tab_value = useSelector(
		(state) => state.reportApp.reportViewDialogReducer.tab_value
	);

	useEffect(()=>{
		setTabValue(tab_value)
	}, [tab_value])

	function handelEdit(data) {
		dispatch(setFromData({
			id: data.id,
			source_name: data.source_name,
			type: data.type,
		}))
		dispatch(openFormViewerDialog())
	}

	function handelDelete() {
		axios.delete(`/service-request-source/delete/${serviceRequestSource.id}`).then((res) => {

			handleClose()
			toast.success('Service ticket source deleted successfully.')
			props.refresh()

		}).catch((err) => {
			console.log(err)
			toast.error(err.response.data.message);
			setServiceRequestSource(null)
		})
	}

	function formatString(str) {
		if (!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) => {
		if (router.query.report_name == undefined) {
			loadInitialState (params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		} else {
			getReport(params);
		}
	}

	function getReport(event) {
		const report_name = router.query.report_name;
		const table_name = "service request source";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
			.then(results => {
				const model = JSON.parse(results.data.data.filter);
				event.api.setFilterModel(model);
				const row_model = JSON.parse(results.data.data.row_model);
				event.columnApi.setColumnState(row_model);
			})
			// autoGroupColumnDef={{ minWidth: 5000 }}
			.catch(err => {
				console.log(err);
			})
	}

	function onFilterChanged(event) {
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		// validation for no-overlay 
		saveView("N/A", rows,gridRef.current.filterModelArr)
		const total_rows = event.api.getDisplayedRowCount();
		if (total_rows === 0) {
			event.api.showNoRowsOverlay();
		} else {
			event.api.hideOverlay();
		}
	}

	function loadInitialState(params) {
		axios
			.get(`/user-view/show-view-order?table_name=${table_name}`)
			.then((results) => {
				const { data } = results.data;
				if (data !== null) {
					const order = JSON.parse(data.order);
					const filter = JSON.parse(data.filter);
					gridRef.current = {...gridRef.current,filterModelArr:filter}

					params?.columnApi.applyColumnState({ query_data: order });
					params?.api.setFilterModel(filter);
					if (Object.keys(filter)?.length) {
						setFilterApplied(true)
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}
	function onSortChanged(params) {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function saveView(rows, filter) {
		let payload = {}
		if (rows == 'N/A') {
			payload = {
				filter: JSON.stringify(filter),
				table_name: table_name
			}
		} else {
			payload = {
				order: JSON.stringify(rows),
				table_name: table_name
			}
		}
		axios.post('/user-view/save-view-order', payload)
			.then(() => {
				console.log('view updated')
			})
			.catch(err => {
				console.log(err);
			})
	}

	const onColumnMoved = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible(params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row => {
			const { hide } = row;
			if (hide) {
				++total_hidden_columns;
			}
		});
		if (total_hidden_columns < total_no_of_columns) {
			saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event) {

		if (event.columns.length == 0) {
			setGroupByArr([])
		} else {
			setGroupByArr(event.columns)
		}

		if (router.query.report_name == undefined) {
			const rows = event.columnApi.getColumnState();
			const first_row = rows[0];
			if (first_row.colId !== "ag-Grid-AutoColumn") {
			  dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			}
		  }
	}

	const onColumnPinned = (params) => {
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e) {
		const payload = {
			edit_id: e.data.id,
			source_name: e.data.source_name_
		}
		if (!payload.source_name) {
			toast.error('Satus Name is required')
			props.refresh()
			return
		}
		axios.post('/service-request-source/edit', payload).then(response => {
			toast.success('Source updated Successfully.')
			props.refresh()
		}).catch(err => {
			console.log('212=>', err);
			toast.error(err.response.data.message || 'Something went wrong!')
			props.refresh()
		})

	}

	function formatLogs(data) {
		return data.map((item) => {
			return {
				id: item.id,
				action_id: `act${item.id}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				source_name: item?.service_request_source?.source_name || item.source_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name
			}
		})
	}

	function getLogs() {
		setLoadingLog(true)
		axios.get('/service-request-source/activities')
			.then(({ data }) => {
				setLogs(formatLogs(data.data.activities));
				setLoadingLog(false)
			})
			.catch(err => {
				console.log('cannot fetch logs');
			})
	}

	const generateHeaderLabel = (header) => {
		if(header === "source_name") {
			return "Service Ticket Source Name";
		}
		return header.split('_').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1)
		}).join(' ')
	}

	const handleChildClick = (value) => {
		setTabValue(value)
	};

	useEffect(()=>{
		if(tabValue == 1){
			getLogs()
			setFilterApplied(false)
		}
	},[tabValue])

	useEffect(() => {
		gridRef.current.api.setQuickFilter(query_data);
	}, [query_data])

	useEffect(() => {
		return () => {
			dispatch(setQuickFilter(null))
		}
	}, [props.serviceRequestSource])

	function handleAddServiceTicketSource() {
		dispatch(openFormViewerDialog())
	}
	return (
		<>
		{ (tabValue == 0) &&
			<AgGridFilterGroupView
				tableRef={gridRef}
				setRowGroupPanelShow={setRowGroupPanelShow}
				groupByArrFromList={groupByArr}
				tableName={'service_request_source'}
				logs={logs}
				displayTableName={'Service Ticket Sources'}
				onClick={handleChildClick}
				onClickAddButton={handleAddServiceTicketSource}
				loadingLog={loadingLog}
			/>}
			{(tabValue == 1) && (<ActivityAgGridListingSameAsSam module={'service_request_source'} logs={logs} displayTableName = {'Service Ticket Sources'}
				addPermission = {props.createSLAggrementPermission}
				loadingActivityLog={loadingLog}
				/>)}
			{
				(tabValue == 0) &&
				<div
					className="ag-theme-alpine ag-single-col"
					style={{
						width: "100%",
						height: windowHeight + 105,
						fontSize: '12px'
					}}
				>
					<AgGridReact
						ref={gridRef}
						onGridReady={onGridReady}
						animateRows={true}
						rowData={props.serviceRequestSource}
						rowHeight={30}
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							enableRowGroup:true,
							resizable: true
						}}

						gridOptions = {{
							suppressHorizontalScroll: false,
							scrollbarWidth: 0
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}

						// Row Grouping conditions
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						rowGroupPanelShow={rowGroupPanelShow}

						onColumnMoved={onColumnMoved}
						onColumnPinned={onColumnPinned}
						onColumnVisible={onColumnVisible}
						onSortChanged={onSortChanged}

						groupHeaderHeight={1}
						// headerHeight = {15}
						floatingFiltersHeight={40}
						frameworkComponents={frameworkComponents}
						// get filter model
						onFilterChanged={onFilterChanged}

						// on column group changed
						onColumnRowGroupChanged = { onColumnRowGroupChanged }

						pagination={true}
					>
						<AgGridColumn
							lockPosition={true}
							rowSelection={'single'}
							width={120}
							minWidth={120}
							maxWidth={120}
							suppressSizeToFit={true}
							headerName="Actions"
							pinned='right'
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({ data }) => {
								if (data) {
									return (
										<>
											{(!data.is_system_generated && props.updateServiceRequestSourcePermission) &&<Tooltip id="edit" title="Edit" placement="top">
											<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-pencil" onClick={() => handelEdit(data)} />
											</Tooltip>}
											
											{(!data.is_system_generated && props.deleteServiceRequestSourcePermission) &&  <Tooltip id="delete" title="Delete" placement="top">
											<i style={{ color: '#1D2939', fontSize: '20px', padding: '0 4px', cursor: 'pointer' }} className="ti ti-trash" onClick={() => handleConfirm(data)} />
											</Tooltip>}
										</>
									)
								} else {
									return (<></>)
								}
							}}
						>
						</AgGridColumn>
						{Object.keys(props.serviceRequestSource[0]).map(header => {
							
							if (!skipHeader.includes(header)) {
								if(header !== "company_name") {
									return(
										<AgGridColumn 
											key = {header} 
											field = {header}
		
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											comparator={customCaseInsensitiveStringSort}
											flex = {1}
											minWidth={280}
											filter = "text"
											// floatingFilter = {true}
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											
										></AgGridColumn>
									)
								}
							}
						})}

						
					</AgGridReact>
					
					<DeleteConfirmModal 
						openAlerModal={serviceRequestSource}
						module={'Service Ticket Source'}
						handleCloseAlertModal={handleClose} 
						handleAlertModal={handelDelete}
					/>

					{/* <GeneralModal
						open={serviceRequestSource}
						title={'Delete Service Request Source'}
						text={<h4>Are you sure to delete this Source named as <b>{serviceRequestSource?.source_name}</b>?</h4>}
						handleClose={handleClose}
						handleConfirm={handelDelete}
					/> */}

				</div>
			}
		</>
	);
}

export default ListView

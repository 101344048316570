import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
	// {
	// 	id: 'applications',
	// 	title: 'Applications',
	// 	translate: 'APPLICATIONS',
	// 	type: 'group',
	// 	icon: 'apps',
	// 	children: [
	// 		{
	// 			id: 'dashboard-component',
	// 			title: 'Dashboard',
	// 			translate: 'Dashboard',
	// 			type: 'item',
	// 			icon: 'dashboard',
	// 			exact: true,
	// 			url: '/dashboard'
	// 		}
	// 	]
	// },

	//Dashboard
	{
		id: 'dashboad-component',
		title: 'Dashboard',
		translate: 'Dashboard',
		// type: 'collapse',
		type: 'item',
		icon: 'layout-dashboard',
		exact: true,
		url: '/dashboard',
		// children: [
		// 	{
		// 		id: 'asset-dashboard-component',
		// 		title: 'Asset Dashboard',
		// 		translate: 'Asset Dashboard',
		// 		type: 'item',
		// 		icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 13C20 15.2091 19.1046 17.2091 17.6569 18.6569L19.0711 20.0711C20.8807 18.2614 22 15.7614 22 13 22 7.47715 17.5228 3 12 3 6.47715 3 2 7.47715 2 13 2 15.7614 3.11929 18.2614 4.92893 20.0711L6.34315 18.6569C4.89543 17.2091 4 15.2091 4 13 4 8.58172 7.58172 5 12 5 16.4183 5 20 8.58172 20 13ZM15.293 8.29297 10.5 12.5 12.5 14.5 16.7072 9.70718 15.293 8.29297Z"></path></svg>,
		// 		url: '/dashboard',
		// 		exact: true,
		// 	}
		// ]
	},
	{
		id: 'dashboard-overview-component',
		title: 'Dashboard',
		translate: 'Dashboard',
		type: 'collapse',
		icon: 'layout-dashboard',
		exact: true,
		url: '/overview',
		children: [
			{
				id: 'super-admin-overview',
				title: 'Overview',
				translate: 'Overview',
				type: 'item',
				icon: 'viewfinder',
				url: '/overview',
				exact: true,
			},
		]
	},
	{
		id: 'overview-component',
		title: 'Logs',
		translate: 'Logs',
		type: 'collapse',
		icon: 'logs',
		exact: true,
		url: '/admin-audit-log',
		children: [
			{
				id: 'admin-audit-log',
				title: 'audit log',
				translate: 'Audit log',
				type: 'item',
				icon: 'brand-windows',
				url: '/admin-audit-log',
				exact: true,
			},
			{
				id: 'activity-log',
				title: 'activity log',
				translate: 'Activity log',
				type: 'item',
				icon: 'brand-blogger',
				url: '/admin-activity-log',
				exact: true,
			}
		]
	},
	
	// {
	// 	id: 'dashboard-component',
	// 	title: 'Dashboard',
	// 	translate: 'Dashboard',
	// 	type: 'item',
	// 	icon: 'dashboard',
	// 	exact: true,
	// 	url: '/dashboard'
	// },


	//Asset Settings
	{
		id: 'asset-setting-component',
		title: 'Assets',
		translate: 'Assets',
		type: 'collapse',
		icon: 'file-stack',
		// url: '/assets-list',
		url: '/assets-list',
		exact: true,
		children: [
			// {
			// 	id: 'assets-component',
			// 	title: 'Asset',
			// 	translate: 'Asset',
			// 	type: 'collapse',
			// 	icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4.5 7.65311V16.3469L12 20.689L19.5 16.3469V7.65311L12 3.311L4.5 7.65311ZM12 1L21.5 6.5V17.5L12 23L2.5 17.5V6.5L12 1ZM6.49896 9.97065L11 12.5765V17.625H13V12.5765L17.501 9.97066L16.499 8.2398L12 10.8445L7.50104 8.2398L6.49896 9.97065Z"></path></svg>,
			// 	url: '/assets',
			// 	exact: true,
			// 	children: [
			// 		{
			// 			id: 'archived-assets-component',
			// 			title: 'Archived Asset',
			// 			translate: 'Archived Asset',
			// 			type: 'item',
			// 			icon: 'archived',
			// 			url: '/archived-assets',
			// 			exact: true,
			// 		},
			// 	]
			// },
			// {
			// 	id: 'assets-dashboard-component',
			// 	title: 'Assets Dashboard',
			// 	translate: 'Assets Dashboard',
			// 	type: 'item',
			// 	icon: 'layout-dashboard',
			// 	url: '/assets-dashboard',
			// 	exact: true,
			// },
			// {
			// 	id: 'assets-catalog-component',
			// 	title: 'Assets Catalog',
			// 	translate: 'Assets Catalog',
			// 	type: 'item',
			// 	icon: 'layout-distribute-horizontal',
			// 	url: '/assets-catalog',
			// 	exact: true,
			// },
			{
				id: 'assets-listing-component',
				title: 'Assets Listing',
				translate: 'Assets Listing',
				type: 'item',
				icon: 'mist',
				url: '/assets-list',
				exact: true,
			},
			{
				id: 'archived-assets-component',
				title: 'Archived Assets',
				translate: 'Archived Assets',
				type: 'item',
				icon: 'archive',
				url: '/archived-assets',
				exact: true,
			},
			{
				id: 'manufacturers-component',
				title: 'Manufacturers',
				translate: 'Manufacturers',
				type: 'item',
				icon: 'tool',
				url: '/manufacturers',
				exact: true,
			},
			{
				id: 'asset-category-component',
				title: 'Asset Categories',
				translate: 'Asset Categories',
				type: 'item',
				icon: 'category',
				url: '/categories',
				exact: true,
			},
			{
				id: 'models-component',
				title: 'Models',
				translate: 'Models',
				type: 'item',
				icon: 'box-model-2',
				url: '/models',
			},
			{
				id: 'accessory-component',
				title: 'Accessories',
				translate: 'Accessories',
				type: 'item',
				icon: 'drone',
				url: '/accessories',
				exact: true,
			},
			{
				id: 'models-custom-attributes',
				title: 'Model Attributes',
				translate: 'Model Attributes',
				type: 'item',
				icon: 'settings-2',
				url: '/model-dynamic-attributes',
				exact: true,
			},
			{
				id: 'asset-status-component',
				title: 'Asset Status',
				translate: 'Asset Status',
				type: 'item',
				icon: 'status-change',
				url: '/asset-statuses',
				exact: true,
			},
			{
				id: 'accessory-category-component',
				title: 'Accessory Categories',
				translate: 'Accessory Categories',
				type: 'item',
				icon: 'category-2',
				url: '/accessory-categories',
				exact: true,
			},
			// {
			// 	id: 'service-request-component',
			// 	title: 'Service Requests',
			// 	translate: 'Service Requests',
			// 	type: 'item',
			// 	icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 4C7.58172 4 4 7.58172 4 12H7C8.10457 12 9 12.8954 9 14V19C9 20.1046 8.10457 21 7 21H4C2.89543 21 2 20.1046 2 19V12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12V19C22 20.1046 21.1046 21 20 21H17C15.8954 21 15 20.1046 15 19V14C15 12.8954 15.8954 12 17 12H20C20 7.58172 16.4183 4 12 4ZM4 14V19H7V14H4ZM17 14V19H20V14H17Z"></path></svg>,
			// 	url: '/view-request-services',
			// 	exact: true,
			// },
			// {
			// 	id: 'new-service-request-component',
			// 	title: 'New Service Request',
			// 	translate: 'New Service Request',
			// 	type: 'item',
			// 	icon: 'room_service',
			// 	url: '/request-service',
			// 	exact: true,
			// },
		]
	},

	//Contract Settings
	{
		id: 'contract-setting-component',
		title: 'Contracts',
		translate: 'Contracts',
		type: 'collapse',
		icon: 'file-text',
		exact: true,
		url: '/contracts',
		children: [
			{
				id: 'contract-component',
				title: 'Contracts Listing',
				translate: 'Contracts Listing',
				type: 'item',
				icon: 'list',
				url: '/contracts',
				exact: true,
			},
			// {
			// 	id: 'contract-component',
			// 	title: 'Contract',
			// 	translate: 'Contract',
			// 	type: 'collapse',
			// 	icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 18H21V6H3V18ZM1 5C1 4.44772 1.44772 4 2 4H22C22.5523 4 23 4.44772 23 5V19C23 19.5523 22.5523 20 22 20H2C1.44772 20 1 19.5523 1 19V5ZM9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11C8.55228 11 9 10.5523 9 10ZM11 10C11 11.6569 9.65685 13 8 13C6.34315 13 5 11.6569 5 10C5 8.34315 6.34315 7 8 7C9.65685 7 11 8.34315 11 10ZM8.0018 16C7.03503 16 6.1614 16.3907 5.52693 17.0251L4.11272 15.6109C5.10693 14.6167 6.4833 14 8.0018 14C9.52031 14 10.8967 14.6167 11.8909 15.6109L10.4767 17.0251C9.84221 16.3907 8.96858 16 8.0018 16ZM16.2071 14.7071L20.2071 10.7071L18.7929 9.29289L15.5 12.5858L13.7071 10.7929L12.2929 12.2071L14.7929 14.7071L15.5 15.4142L16.2071 14.7071Z"></path></svg>,
			// 	url: '/contracts',
			// 	exact: true,
			// 	children: [
			// 		{
			// 			id: 'archived-contract-component',
			// 			title: 'Archived Contract',
			// 			translate: 'Archived Contract',
			// 			type: 'item',
			// 			icon: 'archived',
			// 			url: '/archived-contracts',
			// 			exact: true,
			// 		}
			// 	]
			// },
			{
				id: 'archived-contract-component',
				title: 'Archived Contracts',
				translate: 'Archived Contracts',
				type: 'item',
				icon: 'notebook',
				url: '/archived-contracts',
				exact: true,
			},
			{
				id: 'payment-term-component',
				title: 'Payment Terms',
				translate: 'Payment Terms',
				type: 'item',
				icon: 'report-money',
				url: '/payment-terms',
				exact: true,
			},
			{
				id: 'contract-status-component',
				title: 'Contract Tags',
				translate: 'Contract Tags',
				type: 'item',
				icon: 'file-text',
				url: '/contract-tags',
				exact: true,
			},
			{
				id: 'contract-type-component',
				title: 'Contract Types',
				translate: 'Contract Types',
				type: 'item',
				// icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 21.9966C6.47715 21.9966 2 17.5194 2 11.9966C2 6.47373 6.47715 1.99658 12 1.99658C17.5228 1.99658 22 6.47373 22 11.9966C22 17.5194 17.5228 21.9966 12 21.9966ZM12 19.9966V3.99658C7.58172 3.99658 4 7.5783 4 11.9966C4 16.4149 7.58172 19.9966 12 19.9966Z"></path></svg>,
				icon: 'file-orientation',
				url: '/contract-types',
				exact: true,
			},
			{
				id: 'service-provider-component',
				title: 'Service Providers',
				translate: 'Service Providers',
				type: 'item',
				icon: 'user-check',
				url: '/service-providers',
				exact: true,
			},
			{
				id: 'service-level-agreement-component',
				title: 'Service Level Agreements',
				translate: 'Service Level Agreements',
				type: 'item',
				icon: 'file-like',
				url: '/service-level-agreements',
				exact: true,
			},
		]
	},

	//Subscription Settings
	// {
	// 	id: 'subscription-setting-component',
	// 	title: 'Subscriptions',
	// 	translate: 'Subscriptions',
	// 	type: 'collapse',
	// 	icon: 'file-settings',
	// 	exact: true,
	// 	url: '/subscriptions',
	// 	children: [
	// 		// {
	// 		// 	id: 'subscription-component',
	// 		// 	title: 'Subscription',
	// 		// 	translate: 'Subscription',
	// 		// 	type: 'item',
	// 		// 	icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 11.6458V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11.6458C2.37764 10.9407 2 10.0144 2 9V3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V9C22 10.0144 21.6224 10.9407 21 11.6458ZM19 12.874C18.6804 12.9562 18.3453 13 18 13C16.8053 13 15.7329 12.4762 15 11.6458C14.2671 12.4762 13.1947 13 12 13C10.8053 13 9.73294 12.4762 9 11.6458C8.26706 12.4762 7.19469 13 6 13C5.6547 13 5.31962 12.9562 5 12.874V20H19V12.874ZM14 9C14 8.44772 14.4477 8 15 8C15.5523 8 16 8.44772 16 9C16 10.1046 16.8954 11 18 11C19.1046 11 20 10.1046 20 9V4H4V9C4 10.1046 4.89543 11 6 11C7.10457 11 8 10.1046 8 9C8 8.44772 8.44772 8 9 8C9.55228 8 10 8.44772 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9Z"></path></svg>,
	// 		// 	url: '/subscription',
	// 		// 	exact: true,
	// 		// },
	// 		{
	// 			id: 'subscription-category-component',
	// 			title: 'Subscription Categories',
	// 			translate: 'Subscription Categories',
	// 			type: 'item',
	// 			icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.00488 9.5V4C2.00488 3.44772 2.4526 3 3.00488 3H21.0049C21.5572 3 22.0049 3.44772 22.0049 4V9.5C20.6242 9.5 19.5049 10.6193 19.5049 12C19.5049 13.3807 20.6242 14.5 22.0049 14.5V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V14.5C3.38559 14.5 4.50488 13.3807 4.50488 12C4.50488 10.6193 3.38559 9.5 2.00488 9.5ZM4.00488 7.96776C5.4866 8.70411 6.50488 10.2331 6.50488 12C6.50488 13.7669 5.4866 15.2959 4.00488 16.0322V19H20.0049V16.0322C18.5232 15.2959 17.5049 13.7669 17.5049 12C17.5049 10.2331 18.5232 8.70411 20.0049 7.96776V5H4.00488V7.96776ZM9.00488 9H15.0049V11H9.00488V9ZM9.00488 13H15.0049V15H9.00488V13Z"></path></svg>,
	// 			url: '/subscription-categories',
	// 			exact: true,
	// 		},
	// 		{
	// 			id: 'subscription-status-component',
	// 			title: 'Subscription Statuses',
	// 			translate: 'Subscription Statuses',
	// 			type: 'item',
	// 			icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.00488 9.5V4C2.00488 3.44772 2.4526 3 3.00488 3H21.0049C21.5572 3 22.0049 3.44772 22.0049 4V9.5C20.6242 9.5 19.5049 10.6193 19.5049 12C19.5049 13.3807 20.6242 14.5 22.0049 14.5V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V14.5C3.38559 14.5 4.50488 13.3807 4.50488 12C4.50488 10.6193 3.38559 9.5 2.00488 9.5ZM14.0049 5H4.00488V7.96776C5.4866 8.70411 6.50488 10.2331 6.50488 12C6.50488 13.7669 5.4866 15.2959 4.00488 16.0322V19H14.0049V5ZM16.0049 5V19H20.0049V16.0322C18.5232 15.2959 17.5049 13.7669 17.5049 12C17.5049 10.2331 18.5232 8.70411 20.0049 7.96776V5H16.0049Z"></path></svg>,
	// 			url: '/subscription-statuses',
	// 			exact: true,
	// 		},
	// 		{
	// 			id: 'payment-term-component',
	// 			title: 'Payment Terms',
	// 			translate: 'Payment Terms',
	// 			type: 'item',
	// 			icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.00488 3.00293H21.0049C21.5572 3.00293 22.0049 3.45064 22.0049 4.00293V20.0029C22.0049 20.5552 21.5572 21.0029 21.0049 21.0029H3.00488C2.4526 21.0029 2.00488 20.5552 2.00488 20.0029V4.00293C2.00488 3.45064 2.4526 3.00293 3.00488 3.00293ZM4.00488 5.00293V19.0029H20.0049V5.00293H4.00488ZM8.50488 14.0029H14.0049C14.281 14.0029 14.5049 13.7791 14.5049 13.5029C14.5049 13.2268 14.281 13.0029 14.0049 13.0029H10.0049C8.62417 13.0029 7.50488 11.8836 7.50488 10.5029C7.50488 9.12222 8.62417 8.00293 10.0049 8.00293H11.0049V6.00293H13.0049V8.00293H15.5049V10.0029H10.0049C9.72874 10.0029 9.50488 10.2268 9.50488 10.5029C9.50488 10.7791 9.72874 11.0029 10.0049 11.0029H14.0049C15.3856 11.0029 16.5049 12.1222 16.5049 13.5029C16.5049 14.8836 15.3856 16.0029 14.0049 16.0029H13.0049V18.0029H11.0049V16.0029H8.50488V14.0029Z"></path></svg>,
	// 			url: '/subscription-payment-terms',
	// 			exact: true,
	// 		},
	// 		{
	// 			id: 'cloud-provider-component',
	// 			title: 'Subscription Providers',
	// 			translate: 'Subscription Providers',
	// 			type: 'item',
	// 			icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17 21H7C3.68629 21 1 18.3137 1 15C1 12.3846 2.67346 10.16 5.00804 9.33857C5.0027 9.22639 5 9.11351 5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9C19 9.11351 18.9973 9.22639 18.992 9.33857C21.3265 10.16 23 12.3846 23 15C23 18.3137 20.3137 21 17 21ZM7 19H17C19.2091 19 21 17.2091 21 15C21 12.7909 19.2091 11 17 11C16.8561 11 16.714 11.0076 16.5741 11.0224C16.8479 10.4041 17 9.71979 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.71979 7.15209 10.4041 7.4259 11.0224C7.28596 11.0076 7.14387 11 7 11C4.79086 11 3 12.7909 3 15C3 17.2091 4.79086 19 7 19Z"></path></svg>,
	// 			url: '/subscription-providers',
	// 			exact: true,
	// 		},
	// 	]
	// },

	//service requests
	{
		id: 'service-request-setting-component',
		title: 'Service Tickets',
		translate: 'Service Tickets',
		type: 'collapse',
		icon: 'ticket',
		exact: true,
		url: '/service-tickets',
		children: [
			{
				id: 'service-request-setting-component',
				title: 'Service Tickets',
				translate: 'Service Tickets',
				type: 'item',
				icon: 'ticket',
				url: '/service-tickets',
				exact: true,
			},
			{
				id: 'archived-service-tickets-component',
				title: 'Archived Service Tickets',
				translate: 'Archived Service Tickets',
				type: 'item',
				icon: 'package',
				url: '/archived-service-tickets',
				exact: true,
			},
			{
				id: 'service-request-category-component',
				title: 'Service Ticket Categories',
				translate: 'Service Ticket Categories',
				type: 'item',
				icon: 'category-2',
				url: '/service-ticket-categories',
				exact: true,
			},
			{
				id: 'service-request-status-component',
				title: 'Service Ticket Statuses',
				translate: 'Service Ticket Statuses',
				type: 'item',
				icon: 'ticket',
				url: '/service-ticket-statuses',
				exact: true,
			},
			{
				id: 'service-request-source-component',
				title: 'Service Ticket Sources',
				translate: 'Service Ticket Sources',
				type: 'item',
				icon: 'brand-denodo',
				url: '/service-ticket-sources',
				exact: true,
			},
			{
				id: 'service-request-priority-component',
				title: 'Service Ticket Priorities',
				translate: 'Service Ticket Priorities',
				type: 'item',
				icon: 'caret-up',
				url: '/service-ticket-priorities',
				exact: true,
			}
		]
	},

	//System Settings
	{
		id: 'system-setting-component',
		title: 'System Settings',
		translate: 'System Settings',
		type: 'collapse',
		icon: 'settings',
		exact: true,
		url: '/roles',
		children: [
			{
				id: 'roles',
				title: 'Role',
				translate: 'Roles',
				type: 'item',
				icon: 'user',
				url: '/roles',
				exact: true,
			},
			{
				id: 'shipping-component',
				title: 'Shipping Locations',
				translate: 'Locations',
				type: 'item',
				icon: 'current-location',
				url: '/locations',
				exact: true,
			},
			{
				id: 'location-type-component',
				title: 'Location Types',
				translate: 'Location Types',
				type: 'item',
				icon: 'location',
				url: '/location-types',
				exact: true,
			},
			{
				id: 'department',
				title: 'Department',
				translate: 'Departments',
				type: 'item',
				icon: 'box-align-top-left',
				url: '/departments',
				exact: true,
			},
			{
				id: 'users',
				title: 'User',
				translate: 'Users',
				type: 'item',
				icon: 'users',
				url: '/users',
				exact: true,
			},
			{
				id: 'insights-log-component',
				title: 'Insights Log',
				translate: 'Insights Log',
				type: 'item',
				icon: 'table',
				url: '/insights-log',
				exact: true,
			},
			{
				id: 'customer-management',
				title: 'Customer Management',
				translate: 'Customer Management',
				type: 'item',
				icon: 'user-dollar',
				url: '/customer-management',
				exact: true,
			},
			{
				id: 'agent-management',
				title: 'Agent Management',
				translate: 'Agent Management',
				type: 'item',
				icon: 'user-bolt',
				url: '/agent-management',
				exact: true,
			},
			{
				id: 'activity-log',
				title: 'Activity Log',
				translate: 'Activity Log',
				type: 'item',
				icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 21C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3ZM15 10H4V19H15V10ZM20 10H17V19H20V10ZM20 5H4V8H20V5Z"></path></svg>,
				url: '/activity-log',
				exact: true,
			},
			// {
			// 	id: 'user-audit-log',
			// 	title: 'Audit Logs',
			// 	translate: 'Audit Logs',
			// 	type: 'item',
			// 	icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3 21C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3ZM15 10H4V19H15V10ZM20 10H17V19H20V10ZM20 5H4V8H20V5Z"></path></svg>,
			// 	url: '/user-audit-logs',
			// 	exact: true,
			// },
			// {
			// 	id: 'integration',
			// 	title: 'Integration',
			// 	translate: 'Integration',
			// 	type: 'item',
			// 	icon: 'border_all',
			// 	url: '/integration',
			// 	exact: true,
			// }
		]
	},
	// Reports
	// {
	// 	id: 'reports-components',
	// 	title: 'Reports',
	// 	translate: 'Reports',
	// 	type: 'collapse',
	// 	icon: 'report-analytics',
	// 	exact: true,
	// 	url: '/standard-reports',
	// 	children: [
	// 		{
	// 			id: 'standard-reports',
	// 			title: 'Standard Reports',
	// 			translate: 'Standard Reports',
	// 			type: 'item',
	// 			icon: 'report-medical',
	// 			url: '/standard-reports',
	// 			exact: true,
	// 		},
	// 		{
	// 			id: 'custom-reports',
	// 			title: 'Custom Reports',
	// 			translate: 'Custom Reports',
	// 			type: 'item',
	// 			icon: 'report',
	// 			url: '/custom-reports',
	// 			exact: true,
	// 		},
	// 	]
	// },
	// {
	// 	id: 'connector-component',
	// 	title: 'Connector',
	// 	translate: 'Connector',
	// 	type: 'collapse',
	// 	icon: 'settings',
	// 	exact: true,
	// 	children: [
	// 		{
	// 			id: 'integration',
	// 			title: 'Integration',
	// 			translate: 'Integration',
	// 			type: 'item',
	// 			icon: 'border_all',
	// 			url: '/integration',
	// 			exact: true,
	// 		},


	// 	]
	// },

	// {
	// 	id: 'roles',
	// 	title: 'Roles',
	// 	translate: 'Roles',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/roles',
	// 	exact: true,
	// },
	// {
	// 	id: 'quote-component',
	// 	title: 'Quotes',
	// 	translate: 'Quotes',
	// 	type: 'item',
	// 	icon: 'request_quote',
	// 	url: '/quotes',
	// 	exact: true,
	// },
	// {
	// 	id: 'new-quote-request',
	// 	title: 'Request Quote',
	// 	translate: 'Request Quote',
	// 	type: 'item',
	// 	icon: 'request_quote',
	// 	url: '/request-quote',
	// 	exact: true,
	// },
	// {
	// 	id: 'requested-quote',
	// 	title: 'View Requested Quote',
	// 	translate: 'View Requested Quote',
	// 	type: 'item',
	// 	icon: 'request_quote',
	// 	url: '/view-request-quote',
	// 	exact: true,
	// },
	// {
	// 	id: 'email-component',
	// 	title: 'Email',
	// 	translate: 'Email',
	// 	type: 'item',
	// 	icon: 'email',
	// 	url: '/',
	// 	exact: true,
	// },
	// {
	// 	id: 'chat-component',
	// 	title: 'Chat',
	// 	translate: 'Chat',
	// 	type: 'item',
	// 	icon: 'chat',
	// 	url: '/chat',
	// 	exact: true,
	// },
	// {
	// 	id: 'contract-component',
	// 	title: 'Contracts',
	// 	translate: 'Contracts',
	// 	type: 'item',
	// 	icon: 'description',
	// 	url: '/contracts',
	// 	exact: true,
	// },
	// {
	// 	id: 'assets-component',
	// 	title: 'Assets',
	// 	translate: 'Assets',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/asset-infos',
	// 	exact: true,
	// },
	// {
	// 	id: 'asset-status-component',
	// 	title: 'Asset Status',
	// 	translate: 'Asset Status',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/asset-status',
	// 	exact: true,
	// },
	// {
	// 	id: 'accessory-component',
	// 	title: 'Accessory',
	// 	translate: 'Accessory',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/accessory',
	// 	exact: true,
	// },
	// {
	// 	id: 'accessory-category-component',
	// 	title: 'Accessory Category',
	// 	translate: 'Accessory Category',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/accessory-category',
	// 	exact: true,
	// },
	// {
	// 	id: 'subscription-component',
	// 	title: 'Subscription',
	// 	translate: 'Subscription',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/subscription',
	// 	exact: true,
	// },
	// {
	// 	id: 'subscription-status-component',
	// 	title: 'Subscription Status',
	// 	translate: 'Subscription Status',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/subscription-status',
	// 	exact: true,
	// },
	// {
	// 	id: 'department',
	// 	title: 'Department',
	// 	translate: 'Department',
	// 	type: 'item',
	// 	icon: 'receipt',
	// 	url: '/department',
	// 	exact: true,
	// },
	// {
	// 	id: 'service-request-component',
	// 	title: 'Service Requests',
	// 	translate: 'Service Requests',
	// 	type: 'item',
	// 	icon: 'room_service',
	// 	url: '/view-request-services',
	// 	exact: true,
	// },
	// {
	// 	id: 'new-service-request-component',
	// 	title: 'New service Requests',
	// 	translate: 'New service Requests',
	// 	type: 'item',
	// 	icon: 'room_service',
	// 	url: '/request-service',
	// 	exact: true,
	// },
	// {
	// 	id: 'catalog',
	// 	title: 'Catalog',
	// 	translate: 'Catalog',
	// 	type: 'item',
	// 	icon: 'receipt',
	// 	url: '/catalogs',
	// 	exact: true,
	// },
	// {
	// 	id: 'shipping-component',
	// 	title: 'Shipping Locations',
	// 	translate: 'Locations',
	// 	type: 'item',
	// 	icon: 'business',
	// 	url: '/locations',
	// 	exact: true,
	// },
	// {
	// 	id: 'manufacturers-component',
	// 	title: 'Manufacturers',
	// 	translate: 'Manufacturers',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/manufacturers',
	// 	exact: true,
	// },
	// {
	// 	id: 'models-component',
	// 	title: 'Models',
	// 	translate: 'Models',
	// 	type: 'collapse',
	// 	icon: 'border_all',
	// 	url: '/models',
	// 	exact: true,
	// 	children: [{
	// 		id: 'models-custom-attributes',
	// 		title: 'Model Dynamic Attributes',
	// 		translate: 'Model Dynamic Attributes',
	// 		type: 'item',
	// 		icon: 'receipt',
	// 		url: '/model-dynamic-attributes',
	// 		exact: true,
	// 	}]
	// },
	// {
	// 	id: 'category-component',
	// 	title: 'Category',
	// 	translate: 'Category',
	// 	type: 'item',
	// 	icon: 'border_all',
	// 	url: '/category',
	// 	exact: true,
	// },
	// {
	// 	id: 'sales-order',
	// 	title: 'Sales Order',
	// 	translate: 'Sales Order',
	// 	type: 'item',
	// 	icon: 'credit_card',
	// 	url: '/sales-orders',
	// 	exact: true,
	// },
	// {
	// 	id: 'marketplace-component',
	// 	title: 'Marketplaces',
	// 	translate: 'Marketplaces',
	// 	type: 'item',
	// 	icon: 'checklist',
	// 	url: '/connectors',
	// 	exact: true,
	// },
	// {
	// 	id: 'invoices',
	// 	title: 'Invoices',
	// 	translate: 'Invoices',
	// 	type: 'item',
	// 	icon: 'receipt',
	// 	url: '/invoices',
	// 	exact: true,
	// },
	// {
	// 	id: 'users',
	// 	title: 'Users',
	// 	translate: 'Users',
	// 	type: 'item',
	// 	icon: 'group',
	// 	url: '/users',
	// 	exact: true,
	// },
	// {
	// 	id: 'administration',
	// 	title: 'Administration',
	// 	translate: 'Administration',
	// 	type: 'item',
	// 	icon: 'admin_panel_settings',
	// 	url: '/',
	// 	exact: true,
	// },
	// {
	// 	id: 'user-management',
	// 	title: 'User Management',
	// 	translate: 'User Management',
	// 	type: 'item',
	// 	icon: 'people',
	// 	url: '/',
	// 	exact: true,
	// }
];

export default navigationConfig;

import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import ActivityAgGridListingSameAsSam from '@fuse/components/ActivitieLogs/activitiesAgGridListingSameAsSam';

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
  const classes = useStyles();
	const table_name = "contract_type";
	const router = useRouter();
	const dispatch = useDispatch();
	const gridRef = useRef();
	const toast = useToast(dispatch);
	const skipHeader = [ 'id' ]
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [contractTypeDetails, setContractTypeDetails] = useState({})
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [logs,setLogs] = useState([]);
	const [filterApplied, setFilterApplied] = useState(false);
	const [loadingActivityLog,setLoadingActivityLog] = useState(false);

	const query_data = useSelector((state) => state.reportApp.reportViewDialogReducer.quick_filter);
	const frameworkComponents = {
		customFilter: CustomFilter,
	};
	const handleClickOpen = () => {
		setOpenDeleteDialog(true);
	};

	const tab_value = useSelector(
		(state) => state.reportApp.reportViewDialogReducer.tab_value
	);

	useEffect(()=>{
		setTabValue(tab_value)
	}, [tab_value])

	function setHeaderName(header){
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};

	const handleConfirm = (data) => {
		setOpenDeleteDialog(true)
		setContractTypeDetails(data)
	}

	const handleChildClick = (value) => {
		setTabValue(value)
	}
	const onGridReady = (params) =>{
		if(router.query.report_name == undefined) {
			loadInitialState (params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]))
		}else{
			setRowGroupPanelShow('always')
			getReport(params);
		}
	}

	const onGridSizeChanged = ()=>{
		// setWindowHeight(window.innerHeight - 290);
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		// to show No result found in column filter
		dispatch(setfilterlist(rows));
		saveView("N/A", rows,gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "contract type";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState(params) {
    axios
      .get(`/user-view/show-view-order?table_name=${table_name}`)
      .then((results) => {
        const { data } = results.data;
        if (data !== null) {
			const order = JSON.parse(data.order);
			const filter = JSON.parse(data.filter);
			gridRef.current = {...gridRef.current,filterModelArr:filter}

			params?.columnApi.applyColumnState({ state: order });
			params?.api.setFilterModel(filter);
			if (Object.keys(filter)?.length) {
				setFilterApplied(true)
			}
		}
      })
      .catch((err) => {
        console.log(err);
      });
  }

  	function saveView(rows, filter) {
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		}
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		const rows = params.columnApi.getColumnState();
		saveView(rows);
		dispatch(setrowGroupModel(rows))
	}

	function onSortChanged(params){
		if (router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			dispatch(setrowGroupModel(rows));
			saveView(rows);
		}
	}

	function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}

	function onColumnRowGroupChanged(event) {
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}

		if (router.query.report_name == undefined) {
			const rows = event.columnApi.getColumnState();
			const first_row = rows[1];
			if (first_row.colId !== "ag-Grid-AutoColumn") {
			  dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			}
		  }
	}

	// auto-size columns on row-grouping		
	// onRowGroupOpenedAutoSize();	

	function onCellValueChanged(e){
		let payload = {
			contract_type: e.data.contract_type_name,
			id:e.data.id,
		}
		if(!payload.contract_type){
			toast.error('Contract Type Name is required')
			props.refresh()
			return
		}
		axios.put('/contracts/update-contract-types', payload).then(response => {

			if(response.status == 201){
				console.log('191=>',response.data.message)
				toast.error(response.data.message)
				props.refresh()
			} else {
			  toast.success('Contract Type updated successfully!')
			  props.refresh()
			}
		  
		}).catch(err => {
		  console.log('212=>',err);
		//   toast.error('Something went wrong!')
		})
	}	

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
		}
	}
	useEffect(()=>{
		gridRef.current.api.setQuickFilter(query_data);
	},[query_data])

	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				contract_type_name: item?.contract_type?.display_name || item.type_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item?.user_company?.company_name,
			}
		})
	}

	function getLogs(){
		setLoadingActivityLog(true)

		axios.get('/contracts/activities')
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
			setLoadingActivityLog(false)
		})
		.catch(err=>{
			console.log(err);
		}).finally(()=>{
		})
	}

	function handelDelete(){
		axios.delete(`/contracts/delete-contract-types/${contractTypeDetails?.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message)
			}else{
				toast.success('Contract Type Deleted Successfully')
				handleClose()
				props.refresh()
			}
		}).catch((err)=>{
			console.log('err',err);
			// toast.error('Something went wrong!');
		})
	}
	useEffect(()=>{
		return () => {
			dispatch(setQuickFilter(null))
		}
	},[props.endUsersAccounts])

	useEffect(()=>{
		if(tabValue == 1){
			getLogs()
			setFilterApplied(false)
		}
	},[tabValue])

	return (			
		<>
		{ (tabValue == 0) &&
			<AgGridFilterGroupView 
				tableRef={gridRef} 
				setRowGroupPanelShow={setRowGroupPanelShow} 
				groupByArrFromList ={ groupByArr }
				tableName= {'Contract Types'}
				displayTableName={'Contract Types'}
				logs={logs}
				onClick={handleChildClick}
			/>}
			{(tabValue == 1) && (<ActivityAgGridListingSameAsSam module={'Contract Types'} logs={logs} displayTableName = {'Contract Types'}
				addPermission = {props.createContractTypePermission}
				loadingActivityLog={loadingActivityLog}
				/>)}
			{(tabValue == 0) &&
				<div className="ag-theme-alpine" style={{width: "100%",height:windowHeight+105,fontSize:'11px'}}>
					<AgGridReact
						ref={gridRef}
						rowData={props.endUsersAccounts}
						onGridReady = { onGridReady }
						animateRows = { true }
						rowHeight = {30}
						onGridSizeChanged = { onGridSizeChanged }
						// Set the desired sheet name
						defaultExcelExportParams={{
							sheetName: 'Dalos-Source'
						}}
						defaultColDef={{
							enableRowGroup:true,
							resizable: true
						}}
						// no rows overlay 
						overlayNoRowsTemplate={'<span>No Result Found</span>'}
						
						// get filter model
						onFilterChanged = { onFilterChanged }		
						onColumnMoved = { onColumnMoved }	
						onColumnVisible = { onColumnVisible }
						onColumnPinned = { onColumnPinned }
						onSortChanged={onSortChanged}
						rowGroupPanelShow={rowGroupPanelShow}
						groupHeaderHeight ={1}
						// headerHeight = {15}
						floatingFiltersHeight = {40}

						//row grouping
						showOpenedGroup={true}
						suppressDragLeaveHidesColumns={true}
						suppressMakeColumnVisibleAfterUnGroup={true}
						frameworkComponents={frameworkComponents}
						onColumnRowGroupChanged = { onColumnRowGroupChanged }

						pagination={true}
					>
						{/* <AgGridColumn
							headerName="Actions"
							lockPosition={true}
							rowSelection={'single'}
							width={100}
							minWidth={100}
							maxWidth={100}
							suppressSizeToFit={true}
							pinned= 'right'
							className="action_field"
							lockPinned={true}
							cellRendererFramework={({data}) =>{
								if(data){
									return (
										<>
											{props.updateContractTypePermission && <Tooltip id="edit" title="Edit" placement="top">
												<Icon 
													onClick={() => handelEdit(data) }
													style={{
														color: '#1d2939',
														paddingBottom: '5px',
														paddingRight:'30px',
														fontSize:'15px',
														cursor:'pointer'
													}}>
														edit
													</Icon>
											</Tooltip>}

											{props.deleteContractTypePermission && <Tooltip id="edit" title="Delete" placement="top" >
												<Icon
													onClick={() => handleConfirm(data) }
													style={{
														color: '#1d2939',
														paddingBottom: '3px',
														paddingRight:'30px',
														fontSize:'15px',
														cursor:'pointer'
													}}>
														delete
												</Icon>
											</Tooltip>}
											
										</>
									)
								}else{
									return (<></>)
								}
							}}
						>
						</AgGridColumn> */}
						{
							Object.keys(props.endUsersAccounts[0]).map((header)=>{
								if(!skipHeader.includes(header)){
									return(
										<AgGridColumn 
											field={header}
											key= {header} 
											// editable={props.updateContractTypePermission}
											onCellValueChanged = {onCellValueChanged}
											headerName={ setHeaderName(header) } 
											sortable={ true } filter='text' flex = {1}
											minWidth={200}
											//  floatingFilter = { true }
											
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										></AgGridColumn>
									)
								}
							})
						}
					</AgGridReact>
				</div>
			}
			<GeneralModal
				open={openDeleteDialog}
				title={'Delete Contract Type'}
				text={<h4>Are you sure to delete this Contract Type named as <b>{contractTypeDetails?.contract_type_name}</b>?</h4>}
				handleConfirm={handelDelete}
				handleClose={handleClose}
			/>
		</>
	);
}

export default ListView

import FusePageCardedOne from '@fuse/core/FusePageCardedOne';
import { React, useState, useRef, useEffect } from "react";
import { Button, Box, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Select, MenuItem, Tooltip } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { Editor } from '@tinymce/tinymce-react';
import NotesIcon from '../icons/emojione-v1_note-pad.png';
import { useRouter } from '@fuse/hooks';
import axios from '@fuse/utils/axios';
import Conversation from "./Tabs/Conversation";
import Contract from "./Tabs/Contract";
import Activities from "./Tabs/Activities";
import Note from "./Tabs/Notes";
import useToast from '@fuse/hooks/useToast'
import { useDispatch } from 'react-redux';
import DeleteConfirmModal from '@fuse/components/Modal/DeleteConfirmModal';
import { ucFirst } from 'helper/common';
import { SearchDropDownListPaginationComponent } from 'helper/SearchDropDownPaginationList';
import { getAllUser } from 'app/api/user';
import Files from './Tabs/Files';

const useStyles = makeStyles({
	layoutRoot: {
		background:'#fcfcfc'
	},
	pullRight:{
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end"
	},
	buttonContainer:{
		display: 'flex',
		flexDirection: 'row'
	},
	buttonSquare:{
		borderRadius: '5px'
	},
	topBg:{
		background:'#fcfcfc'
	},
	contentCard:{
		marginBottom:"15px",
		borderRadius:"5px"
	}
});


function ListViewDetails(props) {
	const classes = useStyles();
    const conversationComponentRef = useRef(null);
    const activityComponentRef = useRef(null)
    const router = useRouter()
    const requestId = router.params.id ?? props?.serviceRequestId;
	const [serviceTabValue, setServiceTabValue] = useState(0);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);
	const [openAddNote, setOpenAddNote] = useState(false);
	const [replyMode, setReplyMode] = useState(false);
	const [status, setStatus] = useState('');
	const [agentName, setAgentName] = useState('');
	const [categoryName, setCategoryName] = useState('');
	const [requester, setRequester] = useState();
    const [sourceName, setSourceName] = useState(1);
    const [priorityName, setPriorityName] = useState('');
    const [type, setType] = useState('');
    const [ serviceRequestDetails, setServiceRequestDetails ] = useState(null);
    const [ serviceRequestStatus, setServiceRequestStatus ] = useState([]);
    const [ serviceRequestCategory, setServiceRequestCategory ] = useState([]);
    const [ serviceRequestSource, setServiceRequestSource ] = useState([]);
    const [ serviceRequestPriority, setServiceRequestPriority ] = useState([]);
    const [ agentList, setAgentList ] = useState([]);
    const [ note, setNote ] = useState('');
    const [ noteList, setNoteList ] = useState([]);
    const [ emptyNoteError, setEmptyNoteError ] = useState(false);
    const [ editNoteData, setEditNoteData ] = useState(null);
    const [ refreshNote, setRefreshNote ] = useState(false);
    const [ openConfirmModal, setOpenConfirmModal ] = useState(false);
    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [userApiParams, setUserApiParams] = useState({})
    const [hasConversationStart, setHasConversationStart] = useState(false);
    const [ files, setFiles ] = useState([])

    const types = [
        {
            id: 1,
            name: 'Incident'
        },
        {
            id: 1,
            name: 'Request'
        }
    ]

    const updateServiceTicket = (id, option, value = '') => {
        let payload={
            id,
            option,
            service_ticket_id: requestId
        }

        if(option === 'type'){
            payload={
                ...payload,
                type: id,
                id: 1
            }
        }
        axios.post(`service/details-page-edit`, payload).then((res)=>{
            activityComponentRef.current?.fetchActivities()
            toast.success('Service Ticket updated successfully.')
            if(option === 'requester'){
                fetchServiceRequestDetails()
            }
        })
        .catch((err)=>console.log(err))
        .finally(()=>{
            conversationComponentRef.current?.checkServiceRequestHasAccessToChat()
        })
    }

    const handleChangeRequester = (value) => {
        setRequester({
            id: value.id,
            first_name: value.first_name,
            last_name: value.last_name,
            phone: value.phone,
            email: value.email,
            title: value.title,
            fullName: `${value?.first_name || ''} ${value?.last_name || ''}`,
            label:  `${value?.first_name} ${value?.last_name} (${value.email})`
        })
        updateServiceTicket(value.id, 'requester')
	};

	const handleChangeStatus = (event) => {
		setStatus(event.target.value);
        const status_id = (serviceRequestStatus.filter(item=>item.name == event.target.value))[0].id
        updateServiceTicket(status_id, 'status', event.target.value)
	};

    const handleChangeCategoryName = (event) => {
		setCategoryName(event.target.value);
        const category_id = (serviceRequestCategory.filter(item=>item.name == event.target.value))[0].id
        updateServiceTicket(category_id, 'category')
	};

	const handleChangeAgentName = (event) => {
		setAgentName(event.target.value);
        const agent_id = (agentList.filter(item=>item.name == event.target.value))[0].id
        updateServiceTicket(agent_id, 'agent')
	};

    const handleChangeSourceName = (event) => {
		setSourceName(event.target.value);
        const source_id = (serviceRequestSource.filter(item=>item.name == event.target.value))[0].id
        updateServiceTicket(source_id, 'source')
	};

    const handleChangePriorityName = (event) => {
		setPriorityName(event.target.value);
        const priority_id = (serviceRequestPriority.filter(item=>item.name == event.target.value))[0].id
        updateServiceTicket(priority_id, 'priority')
	};

	const handleChangeServiceTabValue = (event, newValue) => {
		setServiceTabValue(newValue);
	};

    const handleChangeType = (event) => {
		setType(event.target.value);
        updateServiceTicket(event.target.value.toLowerCase(), 'type')
	};

	const handleOpenAddNote = () => {
		setOpenAddNote(true)
        setEditNoteData(null)
	};

	const handleCloseAddNote = () => {
		setOpenAddNote(false)
        setNote('')
	};

    function flattenServiceRequest( data ){
		// const tempElement = document.createElement('div');
		// tempElement.innerHTML = data.description;
		// data.description = tempElement.textContent
		return{
			ticket_status_name: data.service_request_status?.status_name || 'N/A',
			ticket_status_color: data.service_request_status?.status_color?.code || '',
			ticket_id: data.ticket_id,
			ticket_title: data.title,
			ticket_description: data.description,
			asset_name: data.asset?.asset_name ?? 'N/A',
			serial_number: data.asset?.serial_number ?? 'N/A',
			asset_id: data.asset?.id ?? null,
			ticket_category: data.service_request_categories?.category_name ?? 'N/A',
			agent: data.agent ? `${data.agent.first_name} ${data.agent.last_name}`: 'N/A',
			ticket_source: data.service_request_source?.source_name,
			ticket_priority: data.service_request_priority?.name,
			type: data.ticket_type,
			ticket_type: `${data.ticket_type.charAt(0).toUpperCase()}${data.ticket_type.slice(1)}`,
			contract: data.contract,
            requestor: data.requestor ? `${data.requestor?.first_name || ''} ${data.requestor?.last_name || ''}` : null,
            requestor_email: data.requestor ? data.requestor.email : '-',
            contract_number: data?.contract?.entity_external_platform_id,
            contract_id: data?.contract?.id,
            requestor_company_name: data.user_company ? data.user_company.company_name : 'N/A',
            company_id: data.user_company ? data.user_company.id : 'N/A'
		}
	}


    function fetchServiceRequestDetails(){
		axios.get(`/service/request/get-details?request_id=${requestId}&archived=${router?.query?.archived ?? false}`).then((res)=>{
            const flattenData = flattenServiceRequest(res.data.data.serviceTicketDetails)
			setServiceRequestDetails(flattenData)
            setCategoryName(flattenData?.ticket_category)
            setAgentName(flattenData?.agent)
            setSourceName(flattenData?.ticket_source)
            setPriorityName(flattenData?.ticket_priority)
            setType(ucFirst(flattenData?.type))
            setStatus(flattenData?.ticket_status_name)
            setNoteList(res.data.data.serviceTicketDetails?.service_request_notes || [])
           
            if(res.data.data.serviceTicketDetails.requestor){
                setRequester({
                    id: res.data.data.serviceTicketDetails.requestor.id,
                    label:  `${res.data.data.serviceTicketDetails.requestor?.first_name} ${res.data.data.serviceTicketDetails.requestor?.last_name}(${res.data.data.serviceTicketDetails.requestor.email})`,
                    key: `${res.data.data.serviceTicketDetails.requestor.id}|${res.data.data.serviceTicketDetails.requestor?.first_name} ${res.data.data.serviceTicketDetails.requestor?.last_name}(${res.data.data.serviceTicketDetails.requestor.email})`,
                    phone: res.data.data.serviceTicketDetails.requestor.phone,
                    email: res.data.data.serviceTicketDetails.requestor.email,
                    title: res.data.data.serviceTicketDetails.requestor.title,
                })
            }
        
		}).catch((err)=>console.log(err))
	}

    function fetchServiceRequestStatus(){
		axios.get("/service-request-status").then((res)=>{
            const systemDefinedStatus = [], rest = [];
			for( let item of res.data.data){
				let obj = { name: item.status_name, id: item.id, color: item.status_color.code };
				if(item.is_system_generated) systemDefinedStatus.push(obj)
				else rest.push(obj)
			}
            setServiceRequestStatus([...systemDefinedStatus, ...rest]);
		}).catch((err)=>console.log(err))
	}
    function fetchServiceRequestCategory(){
		axios.get("/service-request-category").then((res)=>{
            const systemDefinedCategories = [], rest = [];
			for( let item of res.data.data){
				let obj = { id: item.id, name: item.category_name };
				if(item.is_system_generated) systemDefinedCategories.push(obj)
				else rest.push(obj)
			}
            setServiceRequestCategory([...systemDefinedCategories, ...rest])
		}).catch((err)=>console.log(err))
	}

    function fetchAgents(search=''){
		axios.get(`/user/fetch-agent-suggetion?search=${search}`).then((res)=>{
            const users = res.data.data.users.map(item=>({id:item.id,name: item.name}))
            setAgentList(users)
		}).catch((err)=>console.log(err))
	}

    function fetchServiceRequestSource(){
		axios.get(`/service-request-source`).then((res)=>{
            const systemDefinedSource = [], rest = [];
			for( let item of res.data.data){
				let obj = { id: item.id, name: item.source_name };
				if(item.is_system_generated) systemDefinedSource.push(obj)
				else rest.push(obj)
			}

            setServiceRequestSource([...systemDefinedSource, ...rest])

		}).catch((err)=>console.log(err))
	}

    function fetchServiceRequestPriority(){
		axios.get(`/service-request-priority`).then((res)=>{
            const systemDefinedPriorities = [], rest = [];
			for( let item of res.data.data){
				let obj = { id:item.id, name:item.name };
				if(item.is_system_generated) systemDefinedPriorities.push(obj)
				else rest.push(obj)
			}
            setServiceRequestPriority([...systemDefinedPriorities, ...rest]);

		}).catch((err)=>console.log(err))
	}

    function handleAddNote(){
        if(!note?.trim()){
            setEmptyNoteError(true)
            return
        } 
        if(editNoteData){
            axios.post(`service/edit-notes/${editNoteData.data.id}`, {notes:note}).then((res)=>{
                setRefreshNote(true)
                setRefreshNote(false)
                toast.success('Note updated successfully.')
                handleCloseAddNote()
            }).catch((err)=>console.log(err))
        }else{
            axios.post(`service/add-notes/${requestId}`, {notes:note}).then((res)=>{
                setRefreshNote(true)
                setRefreshNote(false)
                toast.success('Note added successfully.')
                handleCloseAddNote()
            }).catch((err)=>console.log(err))
        }
        
    }
    function handleNoteChange(e){
        if(!e.target.value.trim()) setEmptyNoteError(true)
        else setEmptyNoteError(false)
        setNote(e.target.value)
    }

    function redirectToAssetProfile(){
		if(!serviceRequestDetails) return
		router.push(`/assets-list/${serviceRequestDetails.asset_id}`)
	}


    function redirectToContractProfile(){
        console.log("serviceRequestDetails?.contractDetails", serviceRequestDetails)
        if(!serviceRequestDetails) return
		router.push(`/contract-details/${serviceRequestDetails?.contract_id}`)
    }

	useEffect(()=>{
		fetchServiceRequestDetails()
        fetchServiceRequestStatus()
        fetchServiceRequestCategory()
        fetchAgents()
        fetchServiceRequestSource()
        fetchServiceRequestPriority()
	},[]);

    useEffect(()=>{
		fetchServiceRequestDetails()
	},[type]);

    useEffect(()=>{
        setNote(editNoteData?.data?.notes)
    },[editNoteData])

	function a11yProps(index) {
		return {
		  id: `simple-tab-${index}`,
		  'aria-controls': `simple-tabpanel-${index}`,
		};
	}


	const handleOpenReply = () => {
		setReplyMode(true)
	}

	const handleCloseReply = () => {
		setReplyMode(false)
	}

    const handleCloseTicket = () => {
        setStatus('Closed');
        const status_id = (serviceRequestStatus.filter(item=>item.name == 'Closed'))[0].id
        updateServiceTicket(status_id, 'status', 'Closed')
        setOpenConfirmModal(false)
    }

    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false)
    }

    function fetchFiles(){
        if(!requestId) return
        axios.get(`/service/request/get-files/${requestId}`).then((res)=>{
            const { files } = res.data.data;
            setFiles(files)
        }).catch((err)=>{   
            console.log(err);
        })
    }

    useEffect(()=>{
        fetchFiles()
    },[requestId])

	return (
		<>
            <DeleteConfirmModal 
                openAlerModal={openConfirmModal}
                module={'Service Ticket Details'}
                handleCloseAlertModal={handleCloseConfirmModal} 
                handleAlertModal={handleCloseTicket}

            />
			<FusePageCardedOne
				classes={{
					root: classes.layoutRoot,
					toolbar: 'p-0',
					topBg:classes.topBg,
					contentCard:classes.contentCard
				}}
				// header={
				// 	<AssetsDashboardHeader />
				// }
				content={
					<>
                        <div className='flex'>
                            {/* <div>
                                <input type='text' />
                                <ul>
                                    <li>
                                        <small>#DAL11223</small>
                                        <h4>Showing Installation Error</h4>
                                    </li>
                                    <li>
                                        <small>#DAL11223</small>
                                        <h4>Showing Installation Error</h4>
                                    </li>
                                </ul>
                            </div> */}
                            <div className='py-32 w-full'>
                                <Button className='transparent_icon_btn back_btn' onClick={router.back}>
                                    <i className='ti ti-arrow-left' />
                                </Button>
                                <div className='my-12'>
								{/* <div>
									<span className="cstm_black_border_badge_holder open">
										<span className="dot_holder" style={{background: `${serviceRequestDetails?.ticket_status_color}`}}>&nbsp;</span>
										{serviceRequestDetails?.ticket_status_name}
									</span>
								</div> */}
								<FormControl>
									<Select
                                        disabled={status == 'Closed' || router?.query?.archived }
                                        // style={{width: '130px'}}
                                        autoWidth
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										className={`cstm_black_border_badge_holder cstm_select auto_width ${status}`}
										value={status}
										label=""
                                        onChange={handleChangeStatus}
                                        MenuProps={{
                                            sx: {
                                                maxHeight: 400,
                                                marginTop: '10px',
                                                '& .MuiMenuItem-root': {
                                                    whiteSpace: 'normal',
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }
                                        }}
									>
										{serviceRequestStatus.map(item=>(<MenuItem className='cstm_menuitem_list' value={item.name}>
                                            <div className="select_label_holder">
                                                <span className="dot_holder" style={{background: `${item.color}`}}>&nbsp;</span>
                                                <span className="item_name_holder">{item.name}</span>
                                            </div>
										</MenuItem>))}
									</Select>
								</FormControl>
							</div>
                                {/* <div className='mt-10 mb-20'>
                                    <h2 className='main_page_heading mb-10'>Showing Installation Error
                                    <span className='extra_sub_heading'>(Ticket #: DAL11223)</span></h2>

                                    <div className='sub_content_holder md:w-1/3'>
                                        My access is offline because elementum nisl dolor vestibulum eu sed. Sollicitudin libero sem urna mauris. Odio sed egestas interdum pellentesque. Risus neque suspendisse interdum magna nunc id.
                                    </div>
                                </div> */}
                                <div className='mt-10 mb-20'>
                                    <h2 className='main_page_heading mb-10'>{serviceRequestDetails?.ticket_title}
                                        <span className='extra_sub_heading'>(Ticket #: {serviceRequestDetails?.ticket_id})</span></h2>

                                    <div
                                        className='sub_content_holder w-full'
                                        dangerouslySetInnerHTML={{__html: serviceRequestDetails?.ticket_description}}
                                    />
							    </div>
                                <div className='flexbox_holder justify-start'>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Asset Name</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{serviceRequestDetails?.asset_name}</span>
                                            <Tooltip 
                                                title="View Details" 
                                                placement="top"
                                                componentsProps={{
                                                    tooltip: {
                                                    sx: {
                                                        fontFamily: '"Inter", sans-serif',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        bgcolor: '#0C111D',
                                                        color: '#fff'
                                                    },
                                                    },
                                                }}
                                            >
                                                { serviceRequestDetails?.asset_name !== 'N/A' && <i onClick={redirectToAssetProfile} style={{cursor: 'pointer'}} className='ti ti-eye' />}
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Contract Number</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{serviceRequestDetails?.contract_number || 'N/A'}</span>
                                            <Tooltip 
                                                title="View Details" 
                                                placement="top"
                                                componentsProps={{
                                                    tooltip: {
                                                    sx: {
                                                        fontFamily: '"Inter", sans-serif',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        bgcolor: '#0C111D',
                                                        color: '#fff'
                                                    },
                                                    },
                                                }}
                                            >
                                                {serviceRequestDetails?.contract_number && <i onClick={redirectToContractProfile} style={{cursor: 'pointer'}} className='ti ti-eye' />}
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {/* <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Serial Number</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{serviceRequestDetails?.contractDetails?.contract_number || 'N/A'}
                                            <i onClick={redirectToAssetProfile} style={{cursor: 'pointer'}} className='ti ti-eye' />

                                            </span>
                                        </div>
                                    </div> */}
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Company Name</div>
                                        <div className='bottom_holder'>
                                            <span className='deep_heading'>{serviceRequestDetails?.requestor_company_name}</span>
                                        </div>
								    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Requestor</div>
                                        <div className='bottom_holder'>
                                            {(status == 'Closed' || router?.query?.archived  || hasConversationStart || serviceRequestDetails?.ticket_title.trim() !== 'Device disconnected Alert') ?
                                        <span className='deep_heading'>{serviceRequestDetails?.requestor ?? 'N/A'}</span>
                                        :
                                        <FormControl sx={{ minWidth: 200, minHeight:20 }}>
                                        <SearchDropDownListPaginationComponent
                                            disableUnderline={true}
                                            module="User"
                                            // className="no_border_select_holder"
                                            height='25px'
                                            // value={requester?.label ?? ''}
                                            defaultValue={requester}
                                            disableClearable={true}
                                            searchEnabled={true}
                                            disabled={status == 'Closed' || router?.query?.archived  || hasConversationStart || serviceRequestDetails?.ticket_title.trim() !== 'Device disconnected Alert'}
                                            listCall={getAllUser}
                                            emitItem={(value) => {
                                                if(value){
                                                    handleChangeRequester(value)                                                        
                                                }else{
                                                    setRequester()
                                                }
                                            }}
                                            apiParams={{
                                                client_id: serviceRequestDetails?.company_id
                                            }}
                                        />	
                                        </FormControl>	
                                            }

                                            {/* { serviceRequestDetails?.requestor && <span className='deep_heading'>{serviceRequestDetails?.requestor}</span> }
                                            { (status !== 'Closed' && !router?.query?.archived) && */}

                                            {/* }														 */}
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Category</div>
                                        <div className='bottom_holder'>
                                            <FormControl>
                                                <Select
                                                    disabled={status == 'Closed' || router?.query?.archived }
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className="no_border_select_holder"
                                                    value={categoryName}
                                                    label=""
                                                    onChange={handleChangeCategoryName}
                                                    MenuProps={{
                                                        sx: {
                                                            maxHeight: 400,
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {serviceRequestCategory.map(item => (<MenuItem value={item.name}>
                                                        {item.name}
                                                    </MenuItem>))}


                                                    {/* <div className='px-16 py-5'>
                                                        <Button className='secondary_btn w-full py-4'>
                                                            <i className='ti ti-plus' />
                                                            <span>Add New</span>
                                                        </Button>
                                                    </div> */}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Agent Name</div>
                                        <div className='bottom_holder'>
                                            <FormControl>
                                                <Select
                                                    disabled={status == 'Closed' || router?.query?.archived }
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className="no_border_select_holder"
                                                    value={agentName}
                                                    placeholder="N/A"
                                                    onChange={handleChangeAgentName}
                                                    MenuProps={{
                                                        sx: {
                                                            maxHeight: 400,
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {agentList.map(item=>(<MenuItem value={item.name}>
                                                        {item.name}

                                                    </MenuItem>))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Source</div>
                                        <div className='bottom_holder'>
                                            {/* <span className='deep_heading'>Email</span> */}
                                            {/* <FormControl> */}
                                                <Select
                                                    disabled={status == 'Closed' || router?.query?.archived }
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className="no_border_select_holder"
                                                    value={sourceName}
                                                    label=""
                                                    onChange={handleChangeSourceName}
                                                    MenuProps={{
                                                        sx: {
                                                            maxHeight: 400,
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {serviceRequestSource.map(item=>(<MenuItem value={item.name}>
                                                        {item.name}
                                                    </MenuItem>))}
                                                </Select>
                                            {/* </FormControl> */}
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Priority</div>
                                        <div className='bottom_holder'>
                                            {/* <span className='deep_heading'>Email</span> */}
                                            {/* <FormControl> */}
                                                <Select
                                                    disabled={status == 'Closed' || router?.query?.archived }
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className="no_border_select_holder"
                                                    value={priorityName}
                                                    label=""
                                                    onChange={handleChangePriorityName}
                                                    MenuProps={{
                                                        sx: {
                                                            maxHeight: 400,
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {serviceRequestPriority.map(item=>(<MenuItem value={item.name}>
                                                        {item.name}
                                                    </MenuItem>))}
                                                </Select>
                                            {/* </FormControl> */}
                                        </div>
                                    </div>
                                    <div className='each_grey_border_holder'>
                                        <div className='light_heading'>Type</div>
                                        <div className='bottom_holder'>
                                            {/* <span className='deep_heading'>Email</span> */}
                                            {/* <FormControl> */}
                                                <Select
                                                    disabled={status == 'Closed' || router?.query?.archived }
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className="no_border_select_holder"
                                                    value={type}
                                                    label=""
                                                    onChange={handleChangeType}
                                                    MenuProps={{
                                                        sx: {
                                                            maxHeight: 400,
                                                            '& .MuiMenuItem-root': {
                                                                whiteSpace: 'normal',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {types.map(item=>(<MenuItem value={item.name}>
                                                        {item.name}
                                                    </MenuItem>))}
                                                </Select>
                                            {/* </FormControl> */}
                                        </div>
                                        {/* <div className='bottom_holder'>
                                            <span className='deep_heading'>{serviceRequestDetails?.ticket_type}</span>
                                        </div> */}
                                    </div>
                                </div>

                                <div className='flex flex-wrap justify-between items-center mt-20 mb-40'>
                                    <Box className='cstm_tabs_holder mb-8'>
                                        <Tabs value={serviceTabValue} onChange={handleChangeServiceTabValue} aria-label="basic tabs example" className='tabs_main_holder'>
                                            <Tab className='each_tab_holder' label="Conversation" {...a11yProps(0)} />
                                            {/* <Tab className='each_tab_holder' label="Contract " {...a11yProps(1)} /> */}
                                            <Tab className='each_tab_holder' label="Notes History" {...a11yProps(2)} />
                                            <Tab className='each_tab_holder' label={`Files (${files.length})`} {...a11yProps(3)} />
                                            <Tab className='each_tab_holder' label="Activities" {...a11yProps(4)} />
                                        </Tabs>
                                    </Box>
                                    {(status != 'Closed' && !router?.query?.archived) && <div className='mb-8'>
                                        {(serviceTabValue == 1) && <Button disabled={status == 'Closed' || router?.query?.archived } className={`primary_maroon_outlined_btn mr-10 ${router?.query?.archived ? 'secondary_btn' : ''}`} onClick={() => handleOpenAddNote()}>
                                            <i className='ti ti-plus icon' />
                                            <span>Add Note</span>
                                        </Button>}		
                                        <Button disabled={status == 'Closed' || router?.query?.archived } onClick={()=>{setOpenConfirmModal(true)}} className='secondary_btn'>
                                            <i className='ti ti-x icon' />
                                            <span>Close Ticket</span>
                                        </Button>	
                                    </div>}							
                                </div>

                                {serviceTabValue === 0 &&
								    serviceRequestDetails && <Conversation serviceReqDetails={serviceRequestDetails} requestId={requestId} ref={conversationComponentRef} hasConversationStart={(item) => setHasConversationStart(item > 0 ? true : false)}/>
							    }

                                {/* {serviceTabValue === 1 && 
                                    serviceRequestDetails && <Contract contractInfo={serviceRequestDetails?.contract}/>
                                } */}

                                {(serviceTabValue === 1 && !refreshNote) &&
                                    <Note status={status} setOpenAddNote={setOpenAddNote} setEditNoteData={setEditNoteData} serviceRequestId={requestId}/>
                                }

                                {(serviceTabValue === 2 ) &&
                                    <Files serviceRequestId={requestId} />
                                }

                                {serviceTabValue === 3 &&
                                    <Activities ref={activityComponentRef} serviceRequestId={requestId}/>
                                }
                            </div>
                        </div>

						{/* add note modal */}
						<Dialog
							className="cstm_dialog_modal small text-center"
							open={openAddNote}


                            // onClose={handleCloseAddNote}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								<div className='heading_holder flex justify-end items-center w-full'>
									<Button className='transparent_icon_btn' onClick={handleCloseAddNote}>
									<i className="icon ti ti-x" />
									</Button>
								</div>
							</DialogTitle>
							<DialogContent>
								<div>
									<img className='mx-auto' src={NotesIcon} alt='icon' />
									<h4 className='modal_heading'>{!editNoteData?'Add':'Update'} Note</h4>
									<textarea value={note} onChange={handleNoteChange} style={{minHeight: '116px'}} className='cstm_textarea' placeholder='Write your note here' />
                                    {emptyNoteError && <p className='MuiFormHelperText-root' style={{ color: 'red', textAlign: 'left'}}>Note is required!</p>}
								</div>
							</DialogContent>
							<DialogActions className="action_footer_holder p-24">
								<Button onClick={handleAddNote} className="primary_btn">
									<i className='ti ti-plus' />
									<span>{!editNoteData?'Add':'Update'}</span>
								</Button>
							</DialogActions>
						</Dialog>
					</>
				}
				innerScroll
			/>
		</>
	);
}

export default ListViewDetails
import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@material-ui/core';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import useToast from '@fuse/hooks/useToast';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import { openFormViewerDialog, setFromData } from '../store/formViewDialogReducer';
import Tooltip from '@material-ui/core/Tooltip';
import AlphabeticalSorting from '@fuse/utils/alphabeticalSort';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
// import CustomDropdownEditor from '@fuse/components/AgGridFilterGroupView/components/CustomDropdownEditor';
import ActivityAgGridListingSameAsSam from '@fuse/components/ActivitieLogs/activitiesAgGridListingSameAsSam';


function ListView(props) {
	const table_name = "MODEL";
	const router = useRouter();
	const gridRef = useRef();
	const parentRef = useRef();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const skipHeader = [
		'category_name','category_id','dynamic_attribute',
		'dynamic_attribute_mapping','eol','eosl','id','image_link',
		'EOL_date','EOSL_date','image_name','manufacturer_name',
		'manufacturer_id','assets','model_id', 'category', 'manufacturer', 
		"entity_external_platform_id","original_info", "preview_image_link"
	]
	const [ windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false);
	const [ modelDetails, setModelDetails ] = useState({})
	const [ displayButton, setDisplayButton ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ categoryMenu, setCategoryMenu ] = useState([])
	const [ manufacturerMenu, setManufacturerMenu ] = useState([])
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [groupByArr, setGroupByArr] = useState([])
	const [tabValue, setTabValue] = useState(0)
	const [ logsLoading,setLogsLoading ] = useState(false)
	const [filterApplied, setFilterApplied] = useState(false);
	// const query_data = useSelector((state) => state.modelApp.filterParamsReducer);
	// const filterModel = useSelector((state) => state.modelApp.filterParamsReducer.filter);
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );
	const tab_value = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.tab_value
  );

	const handleClickOpen = () => {
		setOpenDeleteDialog(true);
	};

	const handleClose = () => {
		setOpenDeleteDialog(false);
	};

	function handelEdit(data){
		dispatch(setFromData(data))
		dispatch(openFormViewerDialog())
	}

	const handleDeleteDialogOpen = (data) => {
		handleClickOpen()
		setModelDetails(data)
	}

	function handelDelete(id){
		axios.delete(`/models/delete/${id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message);
				handleClose()
			} else {
				toast.success(res.data.message)
				handleClose()
				props.refresh()
			}
		}).catch((err)=>{
			console.log('err',err.response);
			toast.error(err.response.data.message);
		})
	}

	function formatString(str){
			if(!str) return ''
			return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) =>{
		props.endUsersAccounts.forEach((list) => {
			for(let [fieldName, value] of Object.entries(list)){
				if(value === '' || value == null) list[fieldName] = `No ${formatString(fieldName)}`
			}
		})
		if(router.query.report_name == undefined) {
			loadInitialState (params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
			setRowGroupPanelShow('always')
			getReport(params);
		}
	}
	
	function onFilterChanged(event){
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		saveView("N/A", rows,gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "model";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
		axios.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then(results=>{
			const { data } = results.data;
			if (data !== null) {
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data.filter);
				gridRef.current = {...gridRef.current,filterModelArr:filter}
				params?.columnApi.applyColumnState({ state: order });
				params?.api.setFilterModel(filter);
				if (Object.keys(filter)?.length) {
					setFilterApplied(true)
				}
			}
		})
		.catch(err=>{
			console.log(err);
		})
  }

	function saveView (rows, filter, filterModelArr=null){
		let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filterModelArr ?? filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 
		// const order = JSON.stringify(rows);
		axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
	}

	const onColumnMoved = (params) => {
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

  function onColumnVisible(params){
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      saveView(rows);
			dispatch(setrowGroupModel(rows))
    }
  }

	function onColumnRowGroupChanged(event) {
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
		if (router.query.report_name == undefined) {
			const rows = event.columnApi.getColumnState();
			const first_row = rows[1];
			if (first_row.colId !== "ag-Grid-AutoColumn") {
			  dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			}
		  }
	}

	const frameworkComponents = {
		customFilter: CustomFilter,
		// customDropdownEditor: CustomDropdownEditor
	};

	
	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		let model_name = null
		let model_no = null
		let category = null
		let manufacturer = null
		let description = null
		if(e.colDef.field == "model_name"){
			model_name = e.newValue
		}
		// if(e.colDef.field == "manufacturer_"){
		// 	props.refresh()
		// 	return
		// }
		if(e.colDef.field == "model_no"){
			model_no = e.newValue
		}
		if(e.colDef.field == "category_"){
			category = categoryMenu.find(item=>item.label == e.newValue)
		}
		if(e.colDef.field == "manufacturer_"){
			manufacturer = manufacturerMenu.find(item=>item.label == e.newValue)
		}
		if(e.colDef.field == "description"){
			description = e.newValue
		}
		const payload = {
			id: e.data.id,
			entity_external_platform_id: e.data.entity_external_platform_id,
			model_no: e.data.model_no,
			model_name: e.data.model_name,
			category_id: category ? category.id : e.data.category.id ,
			manufacturer_id: manufacturer ? manufacturer.id : e.data.manufacturer.id,
			description: description ? e.data.description : e.data.original_info.description,
			eol_date :  e.data.eol == 'No Eol'? '': Boolean(e.data.eol) ? e.data.eol.split('T')[0] : '',
			eosl_date:  e.data.eosl == 'No Eosl'? '' : Boolean(e.data.eosl) ? e.data.eosl.split('T')[0] : '',
			image_link: e.data.original_info.image_link,
			image_name: e.data.original_info.image_name
		}
		if(!payload.model_no){
			toast.error('Model Number is required')
			props.refresh()
			return
		} else if(!payload.model_name){
			toast.error('Model Name is required')
			props.refresh()
			return
		} else if(!payload.category_id){
			toast.error('Category Name is required')
			props.refresh()
			return
		} else if(!payload.manufacturer_id){
			toast.error('Manufacturer Name is required')
			props.refresh()
			return
		}
		axios.post('/models/update', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
				//   onSuccess()
				toast.success('Model updated Successfully.')
				props.refresh()
			}		  
		}).catch(err => {
		  console.log('212=>',err);
		//   toast.error('Something went wrong!')
		})
	}

	const onRangeSelectionChanged = (params) => {

		let columnCount = 0 ;
		let startRowIndex = 0 ;
		let endRowIndex =  0 ;
		
		const rows = params.api.getCellRanges();

		if(rows.length != 0){
			columnCount = params.api.getCellRanges()[0].columns.length ;
			startRowIndex = params.api.getCellRanges()[0].startRow.rowIndex ;
			endRowIndex = params.api.getCellRanges()[0].endRow.rowIndex ;			
		}

		if(columnCount > 1 || startRowIndex != endRowIndex  ){
			setDisplayButton(true);
		}else{
			setDisplayButton(false);
		}
	}

	const generateHeaderLabel = (header) => {
		if(header == 'model_no'){
			return 'Model Number'
		}else if(header == 'size_no_of_assets'){
			return 'Size No of Assets'
		}else if(header.includes('_4o94ry')){
			return header.split('_4o94ry')[0]
		}
		return header.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
	},[query_data])

	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	const fetchManufacturerSuggestions = () => {
		setLoading(true)
		let manufacturer = []
		axios.get("/manufacturers").then(response => {
			setLoading(false)
			manufacturer = AlphabeticalSorting(response.data.data,'manufacturer_name')
			setManufacturerMenu(manufacturer.map((item)=>{
				return{
					id: item.id,
					label: item.manufacturer_name
				}
			}))
		}).catch(err => {
			console.log('136=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const fetchCategorySuggestions = () => {
		let category = []
		axios.get('/category?category_type=hardware').then(response => {
			category = AlphabeticalSorting(response.data.data,'category_name')
			setCategoryMenu(category.map((item)=>{
				return{
					id: item.id,
					label: item.category_name
				}
			}))
		}).catch(err => {
			console.log('164=>',err);
			// toast.error('Something went wrong!')
		})
	}

	const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

	function redirectToAssetProfile(event){
		if(event.value == 0){
      		return
    	}
		// localStorage.setItem('model_id',event.data.id)
		openInNewTab(`/assets-list?model_id=${event.data.id}`)
	}

	useEffect(()=>{
		fetchCategorySuggestions()
		fetchManufacturerSuggestions()
		// if(Object.keys(filterModel).length !== 0){
		// 	setTimeout(()=>{
		// 		gridRef.current.api.setFilterModel(filterModel);
		// 	},0)
		// }
	},[])

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//logs
	const [logs,setLogs] = useState([]);

	function formatLogs(data) {
    return data.map((item) => {
      return {
        id: item.id,
        action_id: `act${item.id}`,
        action_date_and_time: getLocalTimeFromUTC(item.created_at),
        model_name: item?.model? `${item?.model.model_no}-${item?.model.model_name}`: item.model_name,
        name: item.user_name,
        action: item.action_name,
        description: item.comment,
        previous_value: item.previous_data,
        current_value: item.current_data,
        field_name: item.action_field_name,
        user_role: item.user_role_name,
		company_name: item?.user_company?.company_name,
      };
    });
  }

  function getLogs() {
	setLogsLoading(true)
    axios
      .get("/models/activities")
      .then(({ data }) => {
        setLogs(formatLogs(data.data.activities));
      })
      .catch((err) => {
				console.log(err)
        console.log("cannot fetch logs");
      }).finally(()=>{
		setLogsLoading(false)
	})
  }

	useEffect(()=>{
		if(tabValue == 1){
			getLogs()
			setFilterApplied(false)
		}
	},[tabValue])

	// const getValues = (status_name, row_id, column_name)=>{
	// 	let name = null;
	// 	name = manufacturerMenu.find(
	// 		(item) => item.label == status_name
	// 	);
	// 	if(!name) return
	// 	let payload = { manufacturer_id: name ? name.id : e.data.status.id,
	// 		model_id: parseInt(row_id)
	// 	}
	// 	axios.post(`/models/edit-manufacturer-in-model`, payload)
	// 	.then(results=>{
	// 		toast.success("Model updated successfully")
	// 		props.refresh();
	// 	})
	// 	.catch(err=>{
	// 		console.log(err);
	// 	})
	// }

	useEffect(()=>{
    setTabValue(tab_value)
  }, [tab_value])
	

	return (			
		<>
		{(tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }  parentRef = { parentRef } onClick={handleChildClick}
				 tableName= {'Model'} logs={logs} loadingLog={logsLoading} displayTableName = {'Models'}
				/>}
		{(tabValue == 1) && (<ActivityAgGridListingSameAsSam module={'Model'} logs={logs} displayTableName = {'Models'}
				addPermission = {props.createSLAggrementPermission}
				loadingActivityLog={logsLoading}
				/>)}
		{/* {(tabValue == 1) && (<ActivityAgGridListing module={'Model'} logs={logs} displayTableName = {'Models'}/>)} */}

			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height: windowHeight+105,
					fontSize: '12px'
				}}
			>
				<AgGridReact
					ref={gridRef}
					suppressRowClickSelection={true}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData={props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}
	
					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }
	
					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged = { onSortChanged }
					groupHeaderHeight ={1}
					floatingFiltersHeight = {40}
					frameworkComponents={frameworkComponents}
	
					// status bar enabled on range selection
					enableRangeSelection={ true }
					statusBar={{
						statusPanels: [
							{ statusPanel: 'agAggregationComponent' },
						],
					}}

					defaultColDef={{
						enableRowGroup:true,
						resizable: true
					}}
	
					// to check the the no. of rows selected during range selection
					onRangeSelectionChanged = { onRangeSelectionChanged }						
	
					onFilterChanged = { onFilterChanged }	
					autoGroupColumnDef={{ width: 150 }}
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }
	
					cacheQuickFilter={true}
					
					pagination={true}

					headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
				>
					{
						Object.keys(props.endUsersAccounts[0]).map((header)=>{
							if(!skipHeader.includes(header)){
								if(header == 'category_'){
									return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											// editable = {props.updateModelPermission}										
											// onCellValueChanged = {onCellValueChanged}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											
											cellEditorParams= {{values:categoryMenu.map(category => category.label)}}
											cellEditor= 'agRichSelectCellEditor'
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										>
										</AgGridColumn>
									)
								}
								if(header == 'manufacturer_'){
									return (
										<AgGridColumn 
											key = {header}
											field = {header}
											cellEditorPopup={true}
											flex = {1}
											minWidth={200}
											suppressSizeToFit = {true}
											// editable = {props.updateModelPermission}										
											// onCellValueChanged = {onCellValueChanged}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											
											cellEditorParams= {{
												values:manufacturerMenu.map(manufacturer => manufacturer.label),
												// getValue: getValues
											}}
											// cellEditor='customDropdownEditor'
											cellEditor='agRichSelectCellEditor'
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										>
										</AgGridColumn>
									)
								}
								if(header == 'size_no_of_assets'){
									return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											cellRendererFramework={(event) =>{
												return (<a 
													style={{
														height:'10px',
														width:'10px',
														color: 'black',
														cursor: 'pointer',
														marginTop: '2px',
														borderRadius: '50%',
														display: 'inline-block',
													}} 
													onClick={()=> redirectToAssetProfile(event)} 
													variant="subtitle1"
												>
													{<><span>{event.value}</span></>}
												</a>)
											}}
										>
										</AgGridColumn>
									)
								}
								if(header == 'source'){
									return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											headerName = {generateHeaderLabel(header)}  
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											
										>
										</AgGridColumn>
									)
								}
								return(
									<AgGridColumn 
										key = {header} 
										field = {header}
										suppressSizeToFit = {true}
										// editable = {props.updateModelPermission}										
										// onCellValueChanged = {onCellValueChanged}
										headerName = {generateHeaderLabel(header)}  
										headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										sortable = {true} 
										filter = "text"
										flex = {1}
										minWidth={200}
										
									></AgGridColumn>
								)
							}
						})
					}
				</AgGridReact>
			</div>}
			<DeleteModelDialog
				openDeleteDialog={openDeleteDialog}
				modelDetails={modelDetails}
				handelDelete={handelDelete}
				handleClose={handleClose}
			/>
		</>
	);
}

export default ListView

function DeleteModelDialog(props) {
	return (
		<div>
			<Dialog
				open={props.openDeleteDialog}
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Delete Model"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<h4>Are you sure to delete this Model named as <b>{props.modelDetails.model_name}</b>?</h4>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleClose}>Cancel</Button>
					<Button onClick={() => props.handelDelete(props.modelDetails.id)} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

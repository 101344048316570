import React, { useEffect, useState,useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import "ag-grid-enterprise";
import "styles/ag-grid-header-style.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import { setGroupViewTabValue } from '../../../../src/app/main/reports/store/reportViewDialogReducer';
import { useDispatch } from "react-redux";
import _ from '@lodash';
import axios from "@fuse/utils/axios";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import FuseLoading from '@fuse/core/FuseLoading';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations'
import TableHeader from '@fuse/components/AgGridFilterGroupView/components/TableHeader'
import {
	TextField
} from '@material-ui/core'
import { formatString } from "@fuse/utils/stringOperations";
import useToast from "@fuse/hooks/useToast";


const useStyles = makeStyles({
    root: {
        '& .MuiDataGrid-columnsContainer': {
            background: '#8f8f8f26'
        }
    }
});

const action_to_color = {
    "CREATED": "green",
    "UPDATED": "orange",
    "ARCHIVED": "red",
    "RESTORED": "blue",
    'DELETED': "red"
}

function ActivityAgGridListing({   displayTableName='' }) {
    const classes = useStyles();
    const [activities, setActivities] = useState([])
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 330);
    const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
    const [groupByArr, setGroupByArr] = useState([])
    const [companyId, setCompanyId] = useState(null)

    const dispatch = useDispatch();
    const gridRef = useRef();
    const toast = useToast(dispatch);

    //for all log in a single place
    const [selectedModuleApi, setSelectedModuleApi] = useState('')
	const [ logsLoading,setLogsLoading ] = useState(false)
	const [ logs,setLogs ] = useState([])
    const [ module, setModule ] = useState('')
	const [selectedValue, setSelectedValue] = useState({})
	const [displayName, setDisplayName] = useState('')
	const moduleWiseActivityApiMap = [
		{name: 'Asset', api: '/assets/get-all-activities', nameInActivity: 'asset', key: 'serial_number'},
		{name: 'Manufacture', api: '/manufacturers/activities', nameInActivity: 'manufacturer', key: 'manufacturer_name'},
		{name: 'Asset Category', api: '/category/activities', nameInActivity: 'asset_category', key: 'category_name'},
		{name: 'Model', api: '/models/activities', nameInActivity: 'model', key: 'model_name'},
		{name: 'Accessory', api: '/accessory/activities', nameInActivity: 'accessory', key: 'accessory_name'},
		{name: 'Model Attribute', api: '/model-custom-attributes/activities', nameInActivity: 'custom_attributes', key: 'attribute_name'},
		{name: 'Asset Status', api: '/asset-status/activities', nameInActivity: 'asset_status', key: 'status_name'},
		{name: 'Accessory Category', api: '/accessory-category/activities', nameInActivity: 'accessory_category', key: 'category_name'},
		{name: 'Contract', api: '/contracts/get-all-activities', nameInActivity: 'contract', key: 'contract_number'},
		{name: 'Payment Term', api: '/payment-term/activities', nameInActivity: 'payment_term', key: 'payment_term_name'},
		{name: 'Contract Status', api: '/contract-status/activities', nameInActivity: 'contract_status', key: 'status_name'},
		{name: 'Contract Type', api: '/contracts/activities', nameInActivity: 'contract_type', key: 'contract_type_name'},
		{name: 'Service Provider', api: '/service-provider/activities', nameInActivity: 'service_provider', key: 'service_provider_name'},
		{name: 'Service Level Agreement', api: '/service-level-agreement/activities', nameInActivity: 'service_level_agreement', key: 'service_level_agreement_name'},
		{name: 'Service Ticket', api: '/service/get-all-activities', nameInActivity: 'service_request', key: 'ticket_id'},
		{name: 'Service Ticket Category', api: '/service-request-category/activities', nameInActivity: 'service_request_category', key: 'category_name'},
		{name: 'Service Ticket Status', api: '/service-request-status/activities', nameInActivity: 'service_request_status', key: 'status_name'},
		{name: 'Service Ticket Source', api: '/service-request-source/activities', nameInActivity: 'service_request_source', key: 'source_name'},
		{name: 'Roles', api: '/user-role/activities', nameInActivity: 'role', key: 'role_name'},
		{name: 'Location', api: '/shipping/activities', nameInActivity: 'shipping', key: 'location_name'},
		{name: 'Location Type', api: '/location-type/activities', nameInActivity: 'location_type', key: 'location_type_name'},
		{name: 'Department', api: '/department/activities', nameInActivity: 'department', key: 'department_name'},
		{name: 'User', api: '/user/all-activities', nameInActivity: 'user', key: 'user_name'},
	]

	function getValue(item, module){
		if(module == 'asset') return item.asset.serial_number
		if(module == 'manufacturer') return item?.manufacturer?.manufacturer_name || item.manufacturer_name
		if(module == 'asset_category') return item?.category?.category_name || item.category_name
		if(module == 'model') return item?.model? `${item?.model.model_no}-${item?.model.model_name}`: item.model_name
		if(module == 'accessory') return item?.accessory?.accessory_name ||  item.accessory_name
		if(module == 'custom_attributes') return item?.model_custom_attributes?.attribute_name || item.attribute_name
		if(module == 'asset_status') return item?.asset_status?.status_name || item.status_name
		if(module == 'accessory_category') return item?.accessory_category?.accessory_category_name || item.category_name
		if(module == 'contract') return item.contract_header?.entity_external_platform_id ?? '-'
		if(module == 'payment_term') return item?.payment_term?.display_name || item.payment_term_name
		if(module == 'contract_status') return item?.contract_status?.contract_status_name || item.contract_status_name
		if(module == 'contract_type') return item?.contract_type?.display_name || item.type_name
		if(module == 'service_provider') return  item?.contract_service_provider?.service_provider_name || item.service_provider_name
		if(module == 'service_level_agreement') return  item?.service_level_agreement?.sla_name || item.sla_name
		if(module == 'service_request') return  item.service_request ? item.service_request.ticket_id : item.ticket_id
		if(module == 'service_request_category') return  item?.service_request_categories?.category_name || item.category_name
		if(module == 'service_request_status') return  item?.service_request_status?.status_name || item.status_name
		if(module == 'service_request_source') return   item?.service_request_source?.source_name || item.source_name
		if(module == 'role') return  item?.role?.display_name || item.role_name
		if(module == 'shipping') return  item?.shipping?.location_name || item.location_name
		if(module == 'location_type') return  item?.location_type?.name || item.type_name
		if(module == 'department') return  item?.department?.department_name || item.department_name
		if(module == 'user') return  item.user? `${item.user.first_name} ${item.user.last_name} (${item.user.email})`: item.end_user_name
	}

	const actionConst = {
    create: 'CREATED',
    update: 'UPDATED',
    delete: 'DELETED',
    set_master: 'SET MASTER',
    archive: 'ARCHIVED',
    restore: 'RESTORED',
    upload_csv_created: 'CSV UPLOAD',
    upload_csv_updated: 'CSV UPLOAD',
    file_added: 'ADDED',
    file_removed: 'REMOVED'
  }

	const formatDateValues = (field_name, value) => {
    if(!value) return '-'
    const dateFields = ['contract_start_date', 'contract_end_date', 'po_date']
    if(dateFields.includes(field_name)) {
      if(field_name === 'po_date') return dateReformatTo_mmm_dd_yyyy(value)
      else return dateReformatTo_mmm_dd_yyyy(new Date(value).toISOString())
    }else{
      return value
    }
  }

	const createDescription = (method, data) => {
    let descriptionText = ''
    switch(method){
      case 'create': {
        descriptionText = `${data.first_name} ${data.last_name} created this contract`
        break
      }
      case 'update': {
        descriptionText = `${data.first_name} ${data.last_name} updated ${formatString(data.field_name)} from ${formatDateValues(data.field_name, data.previous_value)} to ${formatDateValues(data.field_name, data.current_value)}`
        break
      }
      case 'delete': {
        descriptionText = `${data.first_name} ${data.last_name} deleted this contract`
        break
      }
      case 'archive': {
        descriptionText = `${data.first_name} ${data.last_name} archived this contract`
        break
      }
      case 'restore': {
        descriptionText = `${data.first_name} ${data.last_name} restored this contract`
        break
      }
      case 'upload_csv_created': {
        descriptionText = `${data.first_name} ${data.last_name} created this contract`
        break
      }
      case 'upload_csv_updated': {
        descriptionText = `${data.first_name} ${data.last_name} updated this contract`
        break
      }
      case 'set_master': {
        descriptionText = `${data.first_name} ${data.last_name} set this contract as Master for contract ${data.current_value}`
        break
      }
      case 'file_added': {
        descriptionText = `${data.first_name} ${data.last_name} added a file named: ${data.current_value}`
        break;
      }
      case 'file_removed': {
        descriptionText = `${data.first_name} ${data.last_name} removed a file named: ${data.previous_value}`
        break;
      }
      default: {
        descriptionText = ''
        break
      }
    }
    return descriptionText
  }


	function formatLogs(data){
		return data?.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				[selectedValue.key]: getValue(item,selectedValue?.nameInActivity),
				// accessory_name: item?.accessory?.accessory_name ||  item.accessory_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item?.user_company?.company_name,
                email: item?.email
				}
		})
	}

	function formatContractLogs (data) {
		return data.map((item) => {
			return {
				id:item.id,
				email:item.email,
				action_id: `act${item.id}`,
				contract_number: item.contract_header?.entity_external_platform_id ?? '-',
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				name: `${item.user_companies_user?.first_name} ${item.user_companies_user?.last_name}`,
				action: actionConst[item.action_name],
				description: createDescription(item.action_name, { 
					first_name: item.user_companies_user?.first_name, 
					last_name: item.user_companies_user?.last_name,
					field_name: item?.field_name,
					current_value: item?.current_value,
					previous_value: item?.previous_value
				}),
				previous_value: item?.previous_value ? formatDateValues(item?.field_name, item?.previous_value) : '-',
				current_value: item?.current_value ? formatDateValues(item?.field_name, item?.current_value) : '-',
				user_role: item.user_companies_user?.roles_end_user.display_name,
				field_name: formatString(item?.field_name) || '-',
				company_name: item?.user_company?.company_name
			}
		})
	}

	useEffect(()=>{
		if(selectedModuleApi){
            if(!companyId){
                toast.error("Company name is required")
                return
            } 
			setLogsLoading(true)
			axios.get(`${selectedModuleApi}?company_id=${companyId?.id}`)
			.then(({data})=>{
				setLogsLoading(false)
				if(selectedValue.nameInActivity == 'contract'){
					setLogs(formatContractLogs(data.data.activities));
				} else{
					setLogs(formatLogs(data.data.activities));
				}
				
			})
			.catch(err=>{
				console.log(err);
			})
		}
		
	},[selectedModuleApi, companyId])


    const headers = [
        'action_id',
        'serial_number',
        'action_date_and_time',
        'action',
        'field_name',
        'previous_value',
        'current_value',
        'name',
        'email',
        'user_role',
        'description',
        'company_name',
        
    ]

    // updating the header for different module
    if(module === 'asset') headers[1] = 'serial_number'
    else if(module === 'contract') headers[1] = 'contract_number'
    else if(module === 'subscription') headers[1] = 'subscription_name'
    else if(module === 'manufacturer') headers[1] = 'manufacturer_name'
    else if(module === 'asset_category') headers[1] = 'category_name'
    else if(module === 'model') headers[1] = 'model_name'
    else if(module === 'accessory') headers[1] = 'accessory_name'
    else if(module === 'custom_attributes') headers[1] = 'attribute_name'
    else if(module === 'asset_status') headers[1] = 'status_name'
    else if(module === 'accessory_category') headers[1] = 'category_name'
    else if(module === 'subscription_category') headers[1] = 'category_name'
    else if(module === 'subscription_status') headers[1] = 'status_name'
    else if(module === 'payment_term') headers[1] = 'payment_term_name'
    else if(module === 'contract_status') headers[1] = 'status_name'
    else if(module === 'contract_type') headers[1] = 'contract_type_name'
    else if(module === 'service_provider') headers[1] = 'service_provider_name'
    else if(module === 'service_level_agreement') headers[1] = 'service_level_agreement_name'
    else if(module === 'service_request'){
        headers[1] = 'ticket_id'
        headers.push('asset_serial_number')
    } 
    else if(module === 'service_request_category') headers[1] = 'category_name'
    else if(module === 'service_request_status') headers[1] = 'status_name'
    else if(module === 'service_request_source') headers[1] = 'source_name'
    else if(module === 'role') headers[1] = 'role_name'
    else if(module === 'shipping') headers[1] = 'location_name'
    else if(module === 'department') headers[1] = 'department_name'
    else if(module === 'user') headers[1] = 'user_name'
    else if(module === 'location_type') headers[1] = 'location_type_name'
    else if(module === 'subscription_provider') headers[1] = 'provider_name'
    else headers.splice(1, 1)

    function cellRendererFramework (params) {
        if(params.colDef.field === "action"){
            return (
                <span style={{
                    color: action_to_color[params.value],
                    fontWeight: 'bold'
                }}>{params.value}</span>
            )
        }/*else if(params.colDef.headerName === "Group" && params.node.field === "action") {
            // console.log('54: ',params);
            return (
                <span style={{
                    color: action_to_color[params.value],
                    fontWeight: 'bold'
                }}>{params.value}</span>
            )
        }*/
        else if(params.colDef.field === "description" || params.colDef.field === 'previous_value' || params.colDef.field === 'current_value'){
            if(module === 'asset'){
                let desc = ''
                let date = params?.value?.split('{')[1]?.slice(0,-1)
                if (typeof params.value !== 'object' || !params.value) {
                    let date = params?.value?.split('{')[1]?.slice(0,-1)
                    if(date){
                        let dateFormat = Intl.DateTimeFormat('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true
                        }).format(new Date(date));
                        
                        desc = `${params.value.split('{')[0]} ` + dateFormat
                    }else{
                        desc = params.value
                    }
                    return (
                        <abbr style={{textDecoration: 'none', wordBreak: 'break-word'}} title={desc}>{desc}</abbr>
                    )
                }else{
                    desc =(params.value?.word &&  params.value?.number) ? "\u202A" + params.value?.word + ' ' + "\u202C"  + params.value?.number : 'N/A'

                    return (
                        <abbr style={{textDecoration: 'none', wordBreak: 'break-word'}} title={desc}>{desc}</abbr>
                    )
                }
            }else{
                return (
                    <abbr style={{textDecoration: 'none'}} title={params.value}>{params.value}</abbr>
                )
            }
        }
        else{
            return <>{params.value}</>
        }
    }

    const generateHeaderLabel = (header) => {
        return header
          .split("_")
          .map((word) => {
            return word.charAt(0).toUpperCase() + word.substring(1);
          })
          .join(" ");
    };

    const frameworkComponents = {
		customFilter: CustomFilter,
	};

    useEffect(() => {
        setActivities(logs)
        if(logs.length){

            gridRef?.current?.api.hideOverlay();
        }else{
            gridRef.current?.api.showNoRowsOverlay();
        }
    }, [logs])

    function onColumnRowGroupChanged(event){
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
	}

    useEffect(()=>{
        return () => {
            dispatch(setGroupViewTabValue(0))
        }        
    }, [])

    const handleModuleChange = (value) => {
        if(!value) setActivities([])
        setDisplayName(value.name)
        setSelectedModuleApi(value?.api) 
        setSelectedValue(value)
        setModule(value?.nameInActivity)
    }

    const handleCompanyId = (companyId) => {    
        if(!companyId){
            setActivities([])
        } 
        setCompanyId(companyId)
    }

    return (
        <>
        <TableHeader handleModuleChange = { handleModuleChange } handleCompanyId = { handleCompanyId }/>
        {!logsLoading && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
            groupByArrFromList ={ groupByArr }
            tableName= {`activity_logs`} activityModuleName ={module} logs={logs} displayTableName = {displayName} forActivity = {true} hideTableName={true}
        />
        }
        {logsLoading && <FuseLoading/>}
        {<div
            className="ag-theme-alpine"
            style={{
                width: "100%",
                height: windowHeight + 90,
                fontSize: "12px",
            }}
        >
            <AgGridReact
                ref={gridRef}
                animateRows={true}
                rowData={activities}
                rowHeight={28}
                overlayLoadingTemplate={"<span>Loading...</span>"}
                overlayNoRowsTemplate={"<span>No Result Found</span>"}
                showOpenedGroup={true}
                rowGroupPanelShow={rowGroupPanelShow}
                groupHeaderHeight={1}
                pagination={true}
                defaultColDef={{
                    enableRowGroup:true,
                    resizable: true
                }}
                frameworkComponents={frameworkComponents}
                onColumnRowGroupChanged = { onColumnRowGroupChanged }
            >
                {headers.map((header) => {
                    return (
                        <AgGridColumn
                            field={header}
                            key={header}
                            suppressSizeToFit={true}
                            suppressRowClickSelection={true}
                            headerName={generateHeaderLabel(header)}
                            filter="text"
                            minWidth={
                                (
                                    header == 'service_provider_name' ||
                                    header == 'service_level_agreement_name' ||
                                    header == 'action_date_and_time'
                                ) ? 300: 200
                            }
                            sortable={true}
                            cellRendererFramework={cellRendererFramework}
                            
                            headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
                        ></AgGridColumn>
                    );
                })}
            </AgGridReact>
        </div>}
        </>
    )
}

export default ActivityAgGridListing;
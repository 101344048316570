import _ from '@lodash';
import Icon from '@material-ui/core/Icon';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { openFormViewerDialog,setFromData } from '../store/formViewDialogReducer';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";import useToast from '@fuse/hooks/useToast';
import Tooltip from '@material-ui/core/Tooltip';
import GeneralModal from '@fuse/components/Modal/GeneralModal';
import 'ag-grid-enterprise';
import 'styles/ag-grid-header-style.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { setQuickFilter } from '../../reports/store/reportViewDialogReducer';
import { isArray } from "lodash";
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';
import ActivityAgGridListingSameAsSam from '@fuse/components/ActivitieLogs/activitiesAgGridListingSameAsSam';


function ListView(props) {
	const table_name = "ACCESSORY_CATEGORY";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	const toast = useToast(dispatch);
	const [ displayButton, setDisplayButton ] = useState(false);
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 330);
	const [accessoryCategory, setAccessoryCategory] = useState(null)
	const [rowGroupPanelShow, setRowGroupPanelShow] = useState('never');
	const [tabValue, setTabValue] = useState(0)
	const [groupByArr, setGroupByArr] = useState([])
	const [logs,setLogs] = useState([]);
	const [filterApplied, setFilterApplied] = useState(false);
	const [loadingActivityLog,setLoadingActivityLog] = useState(false);

	const skipHeader = [
		"id", "number_of_accessories",
		"user_companies_id", "platform_customer_id",
		"entity_external_platform_id", 
		"created_at","updated_at"
	]

	// const query_data = useSelector((state) => state.accessoryCategoryApp.filterParamsReducer);
	// const filterModel = useSelector((state) => state.accessoryCategoryApp.filterParamsReducer.filter);
	const query_data = useSelector(
    (state) => state.reportApp.reportViewDialogReducer.quick_filter
  );

	const handleConfirm = (data) => {
		setAccessoryCategory(data)
	}

	const handleClose = () => {
		setAccessoryCategory(null)
	}

	const tab_value = useSelector(
		(state) => state.reportApp.reportViewDialogReducer.tab_value
	);

	useEffect(()=>{
		setTabValue(tab_value)
	}, [tab_value])

	function handelEdit(data){
		dispatch(setFromData({
			id: data.id,
			accessory_category_name: data.accessory_category_name,
			description:  data.description
		}))
		dispatch(openFormViewerDialog())
	}
	
	function handelDelete(){
		axios.delete(`/accessory-category/delete/${accessoryCategory.id}`).then((res)=>{
			if(res.status === 201){
				toast.error(res.data.message, 5000);
			}
			else{
				handleClose()
				toast.success(res.data.message)
				props.refresh()
			}
		}).catch((err)=>{
			console.log(err)
			toast.error(err.data.message);
		})
	}
	function formatString(str){
		if(!str) return ''
		return str.split('_').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
	}

	const onGridReady = (params) =>{
		props.endUsersAccounts.forEach((list) => {
			// for(let [fieldName, value] of Object.entries(list)){
			// 	if(value === '') list[fieldName] = `No ${formatString(fieldName)}`
			// }
		})
		if(router.query.report_name == undefined) {
			loadInitialState (params);
			dispatch(setfilterlist({}));
			dispatch(setrowGroupModel([]));
		}else{
			setRowGroupPanelShow('always')
			getReport(params);
		}
	}
	
	function onFilterChanged (event) {
		const rows = event.api.getFilterModel();
		dispatch(setfilterlist(rows));
		saveView("N/A", rows, gridRef.current.filterModelArr)
		// validation for no-overlay 
		const total_rows = event.api.getDisplayedRowCount();
		if(total_rows === 0 ){
			event.api.showNoRowsOverlay();
		}else{
			event.api.hideOverlay();
		}
	}

	function getReport(event){
		const report_name = router.query.report_name;
		const table_name = "accessory category";
		axios.get(`/reports/view-report?table_name=${table_name}&report_name=${report_name}`)
		.then(results=>{
			const model = JSON.parse(results.data.data.filter);
			event.api.setFilterModel(model);
			const row_model = JSON.parse(results.data.data.row_model);
			event.columnApi.setColumnState(row_model);
		})
		.catch(err=>{
			console.log(err);
		})
	}

	function loadInitialState (params){
		axios.get(`/user-view/show-view-order?table_name=${table_name}`)
		.then(results=>{
			const { data } = results.data;
			if (data !== null) {
				const order = JSON.parse(data.order);
				const filter = JSON.parse(data.filter);
				gridRef.current = {...gridRef.current,filterModelArr:filter}
				params?.columnApi.applyColumnState({ state: order });
				params?.api.setFilterModel(filter);
				if (Object.keys(filter)?.length) {
					setFilterApplied(true)
				}
			}
		})
		.catch(err=>{
			console.log(err);
		})
  }

	function saveView(rows, filter, filterModelArr=null) {
    if(isArray(rows) && !rows.length ) return
    let payload = {}
		if(rows == 'N/A'){
			payload = {
				filter:  JSON.stringify(filterModelArr ?? filter),
				table_name : table_name
			}
		}else{
			payload = {
				order:  JSON.stringify(rows),
				table_name : table_name
			}
		} 

    axios.post('/user-view/save-view-order',payload)
		.then(()=>{
			console.log('view updated')
		})
		.catch(err=>{
			console.log(err);
		})
  }

	const onColumnMoved = (params) => {
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView(rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onColumnVisible (params) {
		const rows = params.columnApi.getColumnState();
		dispatch(setrowGroupModel(rows))
		const total_no_of_columns = rows.length;
		let total_hidden_columns = 0;
		rows.forEach(row=>{
			const { hide } = row;
			if(hide) {
				++total_hidden_columns;
			}
		});
		if( total_hidden_columns < total_no_of_columns){
				saveView(params.columnApi.getColumnState());
		}
	}
	function onSortChanged(params){
    if (router.query.report_name == undefined) {
      const rows = params.columnApi.getColumnState();
      saveView(rows);
			dispatch(setrowGroupModel(rows));
    }
  }

	function onColumnRowGroupChanged(event) {
		if(event.columns.length == 0) {
			setGroupByArr([])
		}else{
			setGroupByArr(event.columns)
		}
		if (router.query.report_name == undefined) {
			const rows = event.columnApi.getColumnState();
			const first_row = rows[1];
			if (first_row.colId !== "ag-Grid-AutoColumn") {
			  dispatch(setrowGroupModel(rows));
			  saveView(rows);
			} else {
			  dispatch(setrowGroupModel(rows));
			}
		  }
	}

	const onColumnPinned = (params) =>{
		if(router.query.report_name == undefined) {
			const rows = params.columnApi.getColumnState();
			saveView (rows);
			dispatch(setrowGroupModel(rows))
		}
	}

	function onCellValueChanged(e){
		const payload = {
			edit_id: e.data.id,
			accessory_category_name: e.data.accessory_category_name,
			description: e.data.description
		}
		if(!payload.accessory_category_name){
			toast.error('Accessory Category Name is required')
			props.refresh()
			return
		}
		axios.post('/accessory-category/edit', payload).then(response => {
			if(response.status == 201){
				toast.error(response.data.message)
				props.refresh()
			} else {
			  toast.success('Accessory Category updated Successfully.')
			  props.refresh()
			}		  
		}).catch(err => {
		  console.log('212=>',err);
		//   toast.error('Something went wrong!')
		})
	}

	const generateHeaderLabel = (header) => {
		if(header === "accessory_category_name")  {
			return "Accessory Category";
		} else {
			return header.split('_').map(word => {
				return word.charAt(0).toUpperCase() + word.substring(1)
			}).join(' ')
		}
		
	}

	useEffect(()=>{
		gridRef.current.api.setQuickFilter(
			query_data
		);
		// if(Object.keys(filterModel).length !== 0){
		// 	setTimeout(()=>{
		// 		gridRef.current.api.setFilterModel(filterModel);
		// 	},0)
		// }
	},[query_data])

	useEffect(() => {
		return () => {
				dispatch(setQuickFilter(null))
		}
	}, [])

	const frameworkComponents = {
		customFilter: CustomFilter,
	};

	const handleChildClick = (value) => {
		setTabValue(value)
  };

	//logs
	function formatLogs(data){
		return data.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				category_name: item?.accessory_category?.accessory_category_name || item.category_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item?.user_company?.company_name,
			}
		})
	}

	function getActivities(){
		setLoadingActivityLog(true)
		axios.get(`/accessory-category/activities`)
		.then(({data})=>{
			setLogs(formatLogs(data.data.activities));
			setLoadingActivityLog(false)
		})
		.catch((err)=>{
			console.log('cannot fetch logs',err);
		})
	}

	useEffect(()=>{
		if(tabValue == 1){
			getActivities()
			setFilterApplied(false)
		}
	},[tabValue])

	return (
		<>
		{ (tabValue == 0) && <AgGridFilterGroupView tableRef={gridRef} setRowGroupPanelShow={setRowGroupPanelShow}
				 groupByArrFromList ={ groupByArr }
				 tableName= {'accessory_category'} logs={logs} displayTableName = {'Accessory Categories'}
				 onClick={handleChildClick}
			/>}
			{(tabValue == 1) && (<ActivityAgGridListingSameAsSam module={'accessory_category'} logs={logs} displayTableName = {'Accessory Categories'}
				addPermission = {props.createSLAggrementPermission}
				loadingActivityLog={loadingActivityLog}
				/>)}
			{(tabValue == 0) && <div 
				className="ag-theme-alpine" 
				style={{
					width: "100%",
					height:windowHeight+105,
					fontSize:'12px'
				}}
			>
				<AgGridReact
					ref = { gridRef}
					onGridReady = { onGridReady }
					animateRows = { true }
					rowData = {props.endUsersAccounts}
					rowHeight = {30}
					// Set the desired sheet name
					defaultExcelExportParams={{
						sheetName: 'Dalos-Source'
					}}
					// no rows overlay 
					overlayNoRowsTemplate={'<span>No Result Found</span>'}

					// Row Grouping conditions
					showOpenedGroup={true}
					suppressDragLeaveHidesColumns={true}
					suppressMakeColumnVisibleAfterUnGroup={true}
					rowGroupPanelShow={ rowGroupPanelShow }

					onColumnMoved = { onColumnMoved }
					onColumnPinned = { onColumnPinned }
					onColumnVisible = { onColumnVisible }
					onSortChanged = { onSortChanged }

					groupHeaderHeight ={1}
					frameworkComponents={frameworkComponents}
					floatingFiltersHeight = {40}

					// get filter model
					onFilterChanged = { onFilterChanged }	
					
					// on column group changed
					onColumnRowGroupChanged = { onColumnRowGroupChanged }
					
					pagination={true}

					defaultColDef={{
						enableRowGroup:true,
						resizable: true
					}}
				>

					{Object.keys(props.endUsersAccounts[0]).map(header => {
						if(!skipHeader.includes(header)){
							if(header == 'description'){
								return (
										<AgGridColumn 
											key = {header}
											field = {header}
											suppressSizeToFit = {true}
											editable = {false}										
											onCellValueChanged = {onCellValueChanged}
											headerName = {generateHeaderLabel(header)}  
											sortable = {true}
											filter = "text"
											flex = {1}
											minWidth={200}
											headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
											
											cellRendererFramework={(event) =>{
												return (
													<abbr style={{textDecoration: 'none'}} title={event.value}>{event.value}</abbr>
												)
											}}
										>
										</AgGridColumn>
								)
							}
							if(header != 'user_company'){
								return(
									<AgGridColumn 
										key = {header} 
										field = {header}
										suppressSizeToFit = {true}
										// editable = {header!=='source' && props.updateAccessoryCategoryPermission}										
										// onCellValueChanged = {onCellValueChanged}
										headerName = {generateHeaderLabel(header)}  
										sortable = {true} 
										filter = "text"
										flex = {1}
										minWidth={200}
										headerComponentFramework={(props)=>(CustomFilter(props, header, gridRef))}
										
									></AgGridColumn>
								)
							}
							
						}							
					})}
				</AgGridReact>

				<GeneralModal
					open={accessoryCategory}
					title={'Delete Accessory Category'}
					text={<h4>Are you sure to delete this Accessory Category named as <b>{accessoryCategory?.accessory_category_name}</b>?</h4>}
					handleClose={handleClose}
					handleConfirm={handelDelete}
				/>
			</div>}
		</>
	);
}

export default ListView

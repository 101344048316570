export function formatPrice(price,currency='USD',locale='en-US'){
    if(!Boolean(price) || typeof price !== 'number') return
    
    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    });
    return formatter.format(price);
}

export function formatPriceFieldOnBlur(price){
    if(!price) return
    const value = price.replace(/[,]/g, '');
    const formattedValue = parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
    return formattedValue;
}

export function separateArabicAndNumber(price){
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/;
    if(!arabicRegex) return price
    
    const regex = /([^\d,]+)\s([\d,]+(?:\.\d+)?)/;
    // Use the regular expression to separate the word and the number
    const match = price.match(regex);
  
    if (match) {
      // match[1] is the Arabic word, match[2] is the number
      const cost ={ word: match[1].trim(), number: match[2] }
      
      return { word: match[1].trim(), number: match[2] };
    } else {
      // Return null if the pattern is not found
      return null;
    }
}
import React, {useEffect, useState, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { withRouter } from 'react-router-dom';
import axios from '@fuse/utils/axios';
import { useDispatch } from 'react-redux';
import useToast from '@fuse/hooks/useToast';
import { formatString } from '@fuse/utils/stringOperations';
import { Tooltip } from '@material-ui/core';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

const useStyles = makeStyles({
    root:{
      // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
      //   border: '2px solid black',
      //   display: 'none !important'
      // }
      '& .MuiDataGrid-columnsContainer':{
        background: '#8f8f8f26'
      }
    }
});


const columns = [
    { field: 'id', headerName: 'ID', headerComponentFramework: CustomFilter, minWidth: 100 },
    { field: 'action_date_and_time', headerName: 'Action date and time', minWidth: 250},
    { field: 'action', headerName: 'Action', headerComponentFramework: CustomFilter, minWidth: 120,
        cellRendererFramework: (params) =>{
            if(params.value === "CREATED") return (<span style={{color: "green",fontWeight: 'bold'}}>{params.value}</span>)
            else if(params.value === "UPDATED") return (<span style={{color: "orange",fontWeight: 'bold'}}>{params.value}</span>)
            else if(params.value === "DELETED" || params.value === "ARCHIVED") return (<span style={{color: "red",fontWeight: 'bold'}}>{params.value}</span>)
            else return (<span style={{color: "blue",fontWeight: 'bold'}}>{params.value}</span>)
        }
    },
    { field: 'field_name', headerName: 'Field Name', headerComponentFramework: CustomFilter, minWidth: 200},
    { field: 'previous_value', headerName: 'Previous Value', headerComponentFramework: CustomFilter, minWidth: 250,
        cellRendererFramework: (params) => {
            if(params.value?.length > 20){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText'>{params.value}</span>
                    </Tooltip>
                )
            }else{
                return (<span>{params.value}</span>)
            }
        }
    },
    { field: 'current_value', headerName: 'Current Value', headerComponentFramework: CustomFilter, minWidth: 200,
        cellRendererFramework: (params) => {
            if(params.value?.length > 30){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText'>{params.value}</span>
                    </Tooltip>
                )
            }else{
                return (<span>{params.value}</span>)
            }
        }
    },
    { field: 'name', headerName: 'Name', headerComponentFramework: CustomFilter, minWidth: 200},
    { field: 'role', headerName: 'Role', headerComponentFramework: CustomFilter, minWidth: 200},
    { field: 'description', headerName: 'Description', headerComponentFramework: CustomFilter, minWidth: 400,
        cellRendererFramework: (params) => {
            if(params.value?.length > 50){
                return(
                    <Tooltip title={params.value} placement="top-start">
                        <span className='activitiesTab-descText'>{params.value}</span>
                    </Tooltip>
                )
            }else{
                return (<span>{params.value}</span>)
            }
        }
    },
];


function Activities(props) {

    const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

	const gridRef = useRef()
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
     
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };

    const classes = useStyles();
    const actionConst = {
        create: 'CREATED',
        update: 'UPDATED',
        delete: 'DELETED',
        set_master: 'SET MASTER',
        archive: 'ARCHIVED',
        restore: 'RESTORED',
        upload_csv_created: 'CSV UPLOAD',
        upload_csv_updated: 'CSV UPLOAD',
        file_added: 'ADDED',
        file_removed: 'REMOVED',
        import: 'IMPORT'
    }

    const createDescription = (method, data) => {
        let descriptionText = ''

        switch(method){
            case 'create': {
                descriptionText = `${data.first_name} ${data.last_name} created this contract`
                break
            }

            case 'update': {
                descriptionText = `${data.first_name} ${data.last_name} updated ${formatString(data.field_name)} from ${formatDateValues(data.field_name, data.previous_value)} to ${formatDateValues(data.field_name, data.current_value)}`
                break
            }

            case 'delete': {
                descriptionText = `${data.first_name} ${data.last_name} deleted this contract`
                break
            }

            case 'archive': {
                descriptionText = `${data.first_name} ${data.last_name} archived this contract`
                break
            }

            case 'restore': {
                descriptionText = `${data.first_name} ${data.last_name} restored this contract`
                break
            }

            case 'upload_csv_created': {
                descriptionText = `${data.first_name} ${data.last_name} created this contract`
                break
            }

            case 'upload_csv_updated': {
                descriptionText = `${data.first_name} ${data.last_name} updated this contract`
                break
            }

            case 'set_master': {
                descriptionText = `${data.first_name} ${data.last_name} set this contract as Master for contract ${data.current_value}`
                break
            }

            case 'file_added': {
                descriptionText = `${data.first_name} ${data.last_name} added a file named: ${data.current_value}`
                break;
            }

            case 'file_removed': {
                descriptionText = `${data.first_name} ${data.last_name} removed a file named: ${data.previous_value}`
                break;
            }

            case 'import': {
                descriptionText = `${data.first_name} ${data.last_name} added assets with serial number: ${data.current_value}`
                break
            }

            default: {
                descriptionText = ''
                break
            }
        }

        return descriptionText
    }

    const dispatch = useDispatch()
    const toast = useToast(dispatch)
    const [logs, setLogs] = useState([])

    const formatDateValues = (field_name, value) => {
        if(!value || value === '-') return '-'

        const dateFields = ['contract_start_date', 'contract_end_date', 'po_date', 'billing_cycle_date']
        
        if(dateFields.includes(field_name)) {
            if(field_name === 'po_date') return dateReformatTo_mmm_dd_yyyy(value)
            else return dateReformatTo_mmm_dd_yyyy(new Date(value).toISOString())
        }else{
            return value
        }
    }

    const flattenLogs = (data) => {
        if(!data || data == []) return []

        return data.map(item => ({
            id: `act${item.id}` || '',
            action_date_and_time: getLocalTimeFromUTC(item.created_at),
            name: `${item.user_companies_user?.first_name} ${item.user_companies_user?.last_name}`,
            role: item.user_companies_user?.roles_end_user.display_name,
            action: actionConst[item.action_name],
            field_name: formatString(item?.field_name) || '-',
            current_value: item?.current_value ? formatDateValues(item?.field_name, item?.current_value) : '-',
            previous_value: item?.previous_value ? formatDateValues(item?.field_name, item?.previous_value) : '-',
            description: createDescription(item.action_name, { 
                first_name: item.user_companies_user?.first_name, 
                last_name: item.user_companies_user?.last_name,
                field_name: item?.field_name,
                current_value: item?.current_value,
                previous_value: item?.previous_value
            })
        }))
    }

    const getAllLogs = () => {
        
        axios.get(`/contracts/contract-logs/${props.contract_id}`).then(res => {
            const data = flattenLogs(res.data.data)
            setLogs(data)
        }).catch(err => {
            console.log('106=>',err)
            toast.error('Something went wrong!')
        })
    }

    useEffect(()=>{
        if(!props.contract_id) return
        getAllLogs()
    },[props.contract_id])

    return (
        <>
            {logs.length === 0 && 
                <div className='no_data_holder'>
                    <img src='assets/images/nodata.svg' alt='icon' />
                    <p className='bold_txt'>It's all empty here!</p>
                    <p className='normal_txt'>No data to show yet</p>
                </div>
            }

            {logs.length > 0 && 
                <div 
                    className="ag-theme-alpine" 
                    style={{
                    width: "100%",
                    height: windowHeight+105,
                    fontSize:'12px'
                    }}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={logs}
                        animateRows
                        onGridReady = { onGridReady }
                        paginationPageSize={10}
                        pagination
                        defaultColDef={{
                            resizable: true
                        }}
                    >
                        {columns.map(header => {
                            return(
                                <AgGridColumn 
                                    key = {header.field} 
                                    field = {header.field}
                                    headerName = {header.headerName}  
                                    width = { header.width }
                                    minWidth = { header.minWidth }
                                    maxWidth = { header.maxWidth }
                                    sortable = {false}
                                    flex = {1}
                                    filter = "text"
                                    wrapText
                                    cellRendererFramework = { header.cellRendererFramework}
                                    headerComponentFramework = {header.headerComponentFramework}								
                                />
                            )
                            
                        })}
                    </AgGridReact>
                </div>
            }
            {/* <div className={classes.root} style={{height: 250, width: '100%',overflow:'auto'}}>
                <DataGrid
                    rows={logs}
                    columns={columns}
                    hideFooter={true}
                />
            </div> */}
        </>
    )
}

export default withRouter(Activities)

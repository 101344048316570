import React, {useEffect, useState, useRef} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { withRouter } from 'react-router-dom';
import axios from "@fuse/utils/axios";
import { Tooltip } from '@mui/material';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';

import FuseLoading from '@fuse/core/FuseLoading';
import { getLocalTimeFromUTC } from '@fuse/utils/dateOperations';

const useStyles = makeStyles({
  root:{
    // '& .MuiDataGrid-root-1911 .MuiDataGrid-footer':{
    //   border: '2px solid black',
    //   display: 'none !important'
    // }
    '& .MuiDataGrid-columnsContainer':{
      background: '#8f8f8f26'
    }
  }
});
  
const columns = [
  // { field: 'problem_no', headerName: 'Problem Number', headerComponentFramework: CustomFilter, minWidth: 150, width: 300 },
  // { field: 'recovery_time', headerName: 'Recovery Time', headerComponentFramework: CustomFilter, minWidth: 150, width: 300},
  { field: 'serial_no', headerName: 'Serial Number', headerComponentFramework: CustomFilter, minWidth: 200, width: 300,},
  { field: 'event_severity', headerName: 'Severity', headerComponentFramework: CustomFilter, minWidth: 200, width: 150},
  { field: 'event Date & Time', headerName: 'Event Date & Time', headerComponentFramework: CustomFilter, minWidth: 200, width: 300},
  { field: 'alert_subject', headerName: 'Description', headerComponentFramework: CustomFilter, minWidth: 200, width: 350,
    cellRendererFramework: (params) => {
      return (<span style={{wordBreak: 'break-word'}}>{params.value}</span>)        
    }
  },
  {
    field: "Recovery Time",
    headerName: "Recovery Time",
    minWidth: 250,
    headerComponentFramework: CustomFilter	
  },
  { field: 'event_status', headerName: 'Status', headerComponentFramework: CustomFilter, minWidth: 150, width: 300,
    cellRendererFramework: (event) => {
      return (
          <span
              style={{
                  backgroundColor: event.value.toLowerCase() === 'resolved' ? '#f2ffeb' : '#FFFAEB',
                  color: event.value.toLowerCase() === 'resolved' ? '#4bf709' : '#F79009',
                  fontSize: '12px',
                  fontWeight: '500',
                  border: `1px solid ${event.value.toLowerCase() === 'resolved' ? '#4bfe59' : '#FEC84B'}`,
                  borderRadius: '16px',
                  padding: '2px 16px'
              }}
          >
              {event.value.charAt(0).toUpperCase() + event.value.slice(1).toLowerCase()}
          </span>                                            
      );
    }
  },
  // { field: 'event_duration', headerName: 'Event Duration', headerComponentFramework: CustomFilter, width: 300},
  // { field: 'host_name', headerName: 'Host Name', headerComponentFramework: CustomFilter, width: 300,
  //   cellRendererFramework: (params) => {
  //     return (<span style={{wordBreak: 'break-word'}}>{params.value}</span>)        
  //   }
  // },
  {
    field: "duration",
    headerName: "Duration",
    // cellRendererFramework: AssetActionComponent,
    headerComponentFramework: CustomFilter,
    minWidth: 200,
    maxWidth: 140
  }
];


function IncidentsTab({ contract_id }) {
    const classes = useStyles();    
    const [incidents, setIncidents] = useState([])
    const [loading,setLoading] = useState(false) 
    const [ page, setPage ] = useState(0);
    const [ rowCount, setRowCount ] = useState(0)

    const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 430);

    const gridRef = useRef()
    const onGridReady = params => {
        // Following line to make the currently visible columns fit the screen  
        params.api.sizeColumnsToFit();
      
        // Following line dymanic set height to row on content
        params.api.resetRowHeights();
    };

    function formatData(data){
      if(!data) return []
      return data.map((item) => {
        return {
          id:item.id,
          problem_no: item.problem_id || '-',
          serial_no: item.serial_no || '-',
          event_severity: item.event_severity || '-',
          'event Date & Time': getLocalTimeFromUTC(item.created_at) || '-',
          'Recovery Time': item.recovery_time || '-',
          event_status: item.event_status || '-',
          alert_subject: item.alert_subject || '-',
          duration: item.event_duration || '-',
          host_name: item.host_name
        }
      })
    }

    function onPageChange( event ){
      // {
      //   "page": 1,
      //   "pageCount": 5,
      //   "pageSize": 100,
      //   "paginationMode": "server",
      //   "rowCount": 418
      // }
      setPage(event.page)
    }

    async function getIncidents(){
      setLoading(true);
      try {
        const res = await axios.get(`/contracts/fetch-contract-incident/${contract_id}?limit=${100}&page=${page}`);
        const { rows, count } = res.data.data;
        setIncidents(formatData(rows));
        setRowCount(count)
      } catch (err) {
        console.log(err);
      }finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      getIncidents()
    }, [page])

    if(loading){
      return (
        <FuseLoading/>
      )
    }

    return (
      <>
        {incidents.length === 0 && 
          <div className='no_data_holder'>
              <img src='assets/images/nodata.svg' alt='icon' />
              <p className='bold_txt'>It's all empty here!</p>
              <p className='normal_txt'>No data to show yet</p>
          </div>
        }

        {
          incidents.length > 0 &&      
          <div 
            className="ag-theme-alpine" 
            style={{
            width: "100%",
            height: windowHeight+105,
            fontSize:'12px'
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={incidents}
              animateRows
              onGridReady = { onGridReady }
              paginationPageSize={10}
              pagination
              defaultColDef={{
                resizable: true
              }}
            >
              {columns.map(header => {
                return(
                  <AgGridColumn 
                    key = {header.field} 
                    field = {header.field}
                    headerName = {header.headerName}  
                    width = { header.width }
                    minWidth = { header.minWidth }
                    maxWidth = { header.maxWidth }
                    sortable = {false}
                    flex = {1}
                    filter = "text"
                    wrapText
                    autoHeight = { header.field === 'alert_subject' || header.field === 'host_name' }
                    cellRendererFramework = { header.cellRendererFramework}
                    headerComponentFramework = {header.headerComponentFramework}								
                  />
                )
              })}
            </AgGridReact>
          </div>
        }

        {/* <div className={classes.root} style={{height: 350, width: '100%',overflow:"auto"}}>
          <DataGrid
            rows={incidents}
            columns={columns}
            pagination
            rowsPerPageOptions={[]}
            rowCount={rowCount}
            paginationMode="server"
            onPageChange={ onPageChange }
            page={page}
          />
        </div> */}
      </>
    )
}

export default withRouter(IncidentsTab)

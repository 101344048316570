import _ from '@lodash';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import useRouter from '@fuse/hooks/useRouter';
import { setfilterlist, setrowGroupModel } from '../store/filterParamsReducer';
import axios from "@fuse/utils/axios";
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'styles/ag-grid-header-style.css';
import { setAssetIdForRediection } from '../../assets/store/filterParamsReducer'
import CustomFilter from '@fuse/components/AgGridFilterGroupView/components/CustomFilter';
import AgGridFilterGroupView from '@fuse/components/AgGridFilterGroupView';
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import FuseLoading from '@fuse/core/FuseLoading';
import ActivityAgGridListing from '@fuse/components/ActivitieLogs/activitiesAgGridListing';
import { dateReformatTo_mmm_dd_yyyy, getLocalTimeFromUTC } from '@fuse/utils/dateOperations'
import {
	TextField
} from '@material-ui/core'
import { formatString } from "@fuse/utils/stringOperations";

const useStyles = makeStyles({
	layoutRoot: {},
	head: {
		position: "sticky",
		top: 0
	},
	root: {
		// minWidth: 275,
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

function ListView(props) {
    const classes = useStyles();
	const table_name = "ASSETSTATUS";
	const router = useRouter();
	const gridRef = useRef();
	const dispatch = useDispatch();
	const [fullWidth, setFullWidth] = useState(true);
	const [maxWidth, setMaxWidth] = useState('lg');	
	const [tabState, setTabState] = useState("seats");
	const [windowHeight, setWindowHeight ]  = useState(window.innerHeight - 290);
	const [groupByArr, setGroupByArr] = useState([])
	const [selectedModuleApi, setSelectedModuleApi] = useState('')
	const [ logsLoading,setLogsLoading ] = useState(false)
	const [ logs,setLogs ] = useState([])
	const [selectedValue, setSelectedValue] = useState({})
	const moduleWiseActivityApiMap = [
		{name: 'Asset', api: '/assets/get-all-activities', nameInActivity: 'asset', key: 'serial_number'},
		{name: 'Manufacture', api: '/manufacturers/activities', nameInActivity: 'manufacturer', key: 'manufacturer_name'},
		{name: 'Asset Category', api: '/category/activities', nameInActivity: 'asset_category', key: 'category_name'},
		{name: 'Model', api: '/models/activities', nameInActivity: 'model', key: 'model_name'},
		{name: 'Accessory', api: '/accessory/activities', nameInActivity: 'accessory', key: 'accessory_name'},
		{name: 'Model Attribute', api: '/model-custom-attributes/activities', nameInActivity: 'custom_attributes', key: 'attribute_name'},
		{name: 'Asset Status', api: '/asset-status/activities', nameInActivity: 'asset_status', key: 'status_name'},
		{name: 'Accessory Category', api: '/accessory-category/activities', nameInActivity: 'accessory_category', key: 'category_name'},
		{name: 'Contract', api: '/contracts/get-all-activities', nameInActivity: 'contract', key: 'contract_number'},
		{name: 'Payment Term', api: '/payment-term/activities', nameInActivity: 'payment_term', key: 'payment_term_name'},
		{name: 'Contract Status', api: '/contract-status/activities', nameInActivity: 'contract_status', key: 'status_name'},
		{name: 'Contract Type', api: '/contracts/activities', nameInActivity: 'contract_type', key: 'contract_type_name'},
		{name: 'Service Provider', api: '/service-provider/activities', nameInActivity: 'service_provider', key: 'service_provider_name'},
		{name: 'Service Level Agreement', api: '/service-level-agreement/activities', nameInActivity: 'service_level_agreement', key: 'service_level_agreement_name'},
		{name: 'Service Ticket', api: '/service/get-all-activities', nameInActivity: 'service_request', key: 'ticket_id'},
		{name: 'Service Ticket Category', api: '/service-request-category/activities', nameInActivity: 'service_request_category', key: 'category_name'},
		{name: 'Service Ticket Status', api: '/service-request-status/activities', nameInActivity: 'service_request_status', key: 'status_name'},
		{name: 'Service Ticket Source', api: '/service-request-source/activities', nameInActivity: 'service_request_source', key: 'source_name'},
	]

	function getValue(item, module){
		if(module == 'asset') return item.asset.serial_number
		if(module == 'manufacturer') return item?.manufacturer?.manufacturer_name || item.manufacturer_name
		if(module == 'asset_category') return item?.category?.category_name || item.category_name
		if(module == 'model') return item?.model? `${item?.model.model_no}-${item?.model.model_name}`: item.model_name
		if(module == 'accessory') return item?.accessory?.accessory_name ||  item.accessory_name
		if(module == 'custom_attributes') return item?.model_custom_attributes?.attribute_name || item.attribute_name
		if(module == 'asset_status') return item?.asset_status?.status_name || item.status_name
		if(module == 'accessory_category') return item?.accessory_category?.accessory_category_name || item.category_name
		if(module == 'contract') return item.contract_header?.entity_external_platform_id ?? '-'
		if(module == 'payment_term') return item?.payment_term?.display_name || item.payment_term_name
		if(module == 'contract_status') return item?.contract_status?.contract_status_name || item.contract_status_name
		if(module == 'contract_type') return item?.contract_type?.display_name || item.type_name
		if(module == 'service_provider') return  item?.contract_service_provider?.service_provider_name || item.service_provider_name
		if(module == 'service_level_agreement') return  item?.service_level_agreement?.sla_name || item.sla_name
		if(module == 'service_request') return  item.service_request ? item.service_request.ticket_id : item.ticket_id
		if(module == 'service_request_category') return  item?.service_request_categories?.category_name || item.category_name
		if(module == 'service_request_status') return  item?.service_request_status?.status_name || item.status_name
		if(module == 'service_request_source') return   item?.service_request_source?.source_name || item.source_name
	}

	const actionConst = {
    create: 'CREATED',
    update: 'UPDATED',
    delete: 'DELETED',
    set_master: 'SET MASTER',
    archive: 'ARCHIVED',
    restore: 'RESTORED',
    upload_csv_created: 'CSV UPLOAD',
    upload_csv_updated: 'CSV UPLOAD',
    file_added: 'ADDED',
    file_removed: 'REMOVED'
  }

	const formatDateValues = (field_name, value) => {
    if(!value) return '-'
    const dateFields = ['contract_start_date', 'contract_end_date', 'po_date']
    if(dateFields.includes(field_name)) {
      if(field_name === 'po_date') return dateReformatTo_mmm_dd_yyyy(value)
      else return dateReformatTo_mmm_dd_yyyy(new Date(value).toISOString())
    }else{
      return value
    }
  }

	const createDescription = (method, data) => {
    let descriptionText = ''
    switch(method){
      case 'create': {
        descriptionText = `${data.first_name} ${data.last_name} created this contract`
        break
      }
      case 'update': {
        descriptionText = `${data.first_name} ${data.last_name} updated ${formatString(data.field_name)} from ${formatDateValues(data.field_name, data.previous_value)} to ${formatDateValues(data.field_name, data.current_value)}`
        break
      }
      case 'delete': {
        descriptionText = `${data.first_name} ${data.last_name} deleted this contract`
        break
      }
      case 'archive': {
        descriptionText = `${data.first_name} ${data.last_name} archived this contract`
        break
      }
      case 'restore': {
        descriptionText = `${data.first_name} ${data.last_name} restored this contract`
        break
      }
      case 'upload_csv_created': {
        descriptionText = `${data.first_name} ${data.last_name} created this contract`
        break
      }
      case 'upload_csv_updated': {
        descriptionText = `${data.first_name} ${data.last_name} updated this contract`
        break
      }
      case 'set_master': {
        descriptionText = `${data.first_name} ${data.last_name} set this contract as Master for contract ${data.current_value}`
        break
      }
      case 'file_added': {
        descriptionText = `${data.first_name} ${data.last_name} added a file named: ${data.current_value}`
        break;
      }
      case 'file_removed': {
        descriptionText = `${data.first_name} ${data.last_name} removed a file named: ${data.previous_value}`
        break;
      }
      default: {
        descriptionText = ''
        break
      }
    }
    return descriptionText
  }


	function formatLogs(data){
		return data?.map((item)=>{
			return {
				id:item.id,
				action_id: `act${item.id}`,
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				[selectedValue.key]: getValue(item,selectedValue?.nameInActivity),
				// accessory_name: item?.accessory?.accessory_name ||  item.accessory_name,
				name: item.user_name,
				action: item.action_name,
				description: item.comment,
				previous_value: item.previous_data,
				current_value: item.current_data,
				field_name: item.action_field_name,
				user_role: item.user_role_name,
				company_name: item?.user_company?.company_name
				}
		})
	}

	function formatContractLogs (data) {
		return data.map((item) => {
			return {
				id:item.id,
				action_id: `act${item.id}`,
				contract_number: item.contract_header?.entity_external_platform_id ?? '-',
				action_date_and_time: getLocalTimeFromUTC(item.created_at),
				name: `${item.user_companies_user?.first_name} ${item.user_companies_user?.last_name}`,
				action: actionConst[item.action_name],
				description: createDescription(item.action_name, { 
					first_name: item.user_companies_user?.first_name, 
					last_name: item.user_companies_user?.last_name,
					field_name: item?.field_name,
					current_value: item?.current_value,
					previous_value: item?.previous_value
				}),
				previous_value: item?.previous_value ? formatDateValues(item?.field_name, item?.previous_value) : '-',
				current_value: item?.current_value ? formatDateValues(item?.field_name, item?.current_value) : '-',
				user_role: item.user_companies_user?.roles_end_user.display_name,
				field_name: formatString(item?.field_name) || '-',
				company_name: item?.user_company?.company_name
			}
		})
	}

	useEffect(()=>{
		if(selectedModuleApi){
			setLogsLoading(true)
			axios.get(selectedModuleApi)
			.then(({data})=>{
				setLogsLoading(false)
				if(selectedValue.nameInActivity == 'contract'){
					setLogs(formatContractLogs(data.data.activities));
				} else{
					setLogs(formatLogs(data.data.activities));
				}
				
			})
			.catch(err=>{
				console.log(err);
			})
		}
		
	},[selectedModuleApi])

	return (	
		<>
			{<ActivityAgGridListing /> }
		</>
	);
}

export default ListView

import axios from '@fuse/utils/axios'

const getAllPriority = (params) => axios.get(`/service-request-priority/fetch-paginated-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllStatus = (params) => axios.get(`/service-request-status/fetch-paginated-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllSource = (params) => axios.get(`/service-request-source/fetch-paginated-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllCategory = (params) => axios.get(`/service-request-category/fetch-paginated-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);
const getAllServiceRequest = (params) => axios.get(`/service/fetch-paginated-suggestion?page=${params.page}${params.search ? `&search=${params.search}`: ''}`);

export {
    getAllStatus,
    getAllPriority,
    getAllServiceRequest,
    getAllSource,
    getAllCategory
}
